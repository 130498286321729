import React from 'react'
import GetInstanceUser from '../../../../../utils/GetInstanceUser'
import GetPastedTempUser from '../../../../../utils/GetPastedTempUser'
import { Slider, Box, Typography, FormControl, InputLabel, Select, MenuItem, LinearProgress, Button } from '@mui/material'
import { GlobalContext } from '../../../../../context/GlobalContext'
import { Stack } from '@mui/system'
import moment from 'moment'
import { CampaignContext } from '../../../../../context/CampaignContext'
import { TranslateContext } from '../../../../../context/TranslateContext'

const PastedNumbers = () => {
    const { ptemp } = GetPastedTempUser()
    const { data } = React.useContext(TranslateContext)
    const { instance } = GetInstanceUser()
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const CAMPAIGN_CONTEXT = React.useContext(CampaignContext)
    const [state, setState] = React.useState({
        delay: [2, 10],
        unique_id: Date.now()
    })

    async function handleSend() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/campaign/paste",
            obj: state,
            admin: false,
            post: true
        })
        if (res.data.success) {
            CAMPAIGN_CONTEXT.setData({ ...CAMPAIGN_CONTEXT.data, campaign_done: true })
        }
    }

    function valuetext(value) {
        return `${value}sec`;
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                {ptemp ? (
                    <FormControl size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">{data.select_temp}</InputLabel>
                        <Select
                            labelId="templet-select-label"
                            id="templet-select"
                            // value={""}
                            label={data.select_temp}
                            onChange={(e) => setState({ ...state, templet_paste: e.target.value })}
                        >
                            {ptemp.map((i, key) => {
                                return (
                                    <MenuItem
                                        key={key}
                                        value={i}>
                                        <Stack direction={'column'}>
                                            <Typography variant='caption'>{i.name} ({i?.pastedString?.split(',').length} numbers)</Typography>
                                            <Typography variant='caption' color='gray'>{moment(i.createdAt).format("DD/MM/YYYY hh:mm A")}</Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : <LinearProgress />}



                <Box p={4} borderRadius={2} border={0.5} borderColor='gray' >
                    <Box mb={2} >
                        <Typography variant='caption' color={'gray'} >{data.randpm_delay_to_be} <a style={{ color: 'orange' }} >{state.delay[0]}</a> {data.to} <a style={{ color: 'orange' }} >{state.delay[1]}</a> {data.sec}</Typography>
                    </Box>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={state.delay}
                        onChange={(e, newVal) => setState({ ...state, delay: newVal })}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>

                {instance ? (
                    <FormControl size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">{data.sel_ins}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={""}
                            label={data.sel_ins}
                            onChange={(e) => setState({ ...state, instance: e.target.value })}
                        >
                            {instance.map((i, key) => {
                                return (
                                    <MenuItem
                                        key={key} disabled={i.status === 'ready to use' ? false : true}
                                        value={i}>{i.instance_number === "NULL" ? "NA" : i.instance_number}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : <LinearProgress />}

                <Button
                    disabled={state.templet_paste && state.delay && state.instance ? false : true}
                    onClick={handleSend}
                    variant='contained' size='small' >{data.run_cam}</Button>
            </Stack>
        </div >
    )
}

export default PastedNumbers
import { Divider, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'

const IntroComp = () => {
    return (
        <Box p={3}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='h6'  >Introduction</Typography>
                <Typography color={'gray'}>
                    A cloud based WhatsApp SAAS system where you can make a bulk campaign along with the WhatsApp bot. As this uses whatsapp web instance so there is no hazard of banning whatsapp.
                </Typography>
            </Stack>
            <Box p={1}>
            </Box>
            <Box mt={2} >
                <Typography variant='h6'>Requirements</Typography>
            </Box>
            <ul>
                <Stack direction={'column'} spacing={0.5}>
                    <li style={{ color: 'gray' }} >Vps or Any NodeJs Enabled Shared Hosting</li>
                    <li style={{ color: 'gray' }} >MySQL Database</li>
                    <li style={{ color: 'gray' }} >Domain or Subdomain</li>
                </Stack>
            </ul>
            <Box p={1}>
            </Box>

            <Box mt={2} >
                <Typography variant='h6'>Features</Typography>
            </Box>

            <ul>
                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Multitple Instance</li>
                    <Typography fontSize={12} color='gray' >You can add/save multiple instances that means multiple whatsapp accounts and use them anytime.</Typography>
                </Box>


                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Attachement Supported</li>
                    <Typography fontSize={12} color='gray' >You can send messages along with images and other extensions like audio, video, documents etc.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Button Supported</li>
                    <Typography fontSize={12} color='gray' >You can send messages with single and multiple buttons as well.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >List Supported</li>
                    <Typography fontSize={12} color='gray' >You can send messages along with the lists as well which helps you to get input form the messgae receiver.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Campaigns</li>
                    <Typography fontSize={12} color='gray' >You can create you campaigns with button/messages/images/lists to use them later.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >WhatsApp Bot</li>
                    <Typography fontSize={12} color='gray' >It has a feature where you can set auto pre set replies with buttons/lits/messages/images.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Grab Groups</li>
                    <Typography fontSize={12} color='gray' >You can export WhatsApp groups data in excel format with group name/group members etc win excel format.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Ping System</li>
                    <Typography fontSize={12} color='gray' >You can ping to the support at anytime in a convenient way.</Typography>
                </Box>

                <Box mb={2} >
                    <li style={{ fontWeight: 'bold', fontSize: 15, }} >Much More</li>
                </Box>
            </ul>
        </Box>
    )
}

export default IntroComp
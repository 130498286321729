import { ButtonBase, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'
import { TranslateContext } from '../../context/TranslateContext'
import GetWebSet from '../../utils/GetWebSet'

const Footer = () => {
    const { data } = React.useContext(TranslateContext)
    const [pages, setPages] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [mode, setMode] = React.useState("")
    const { web_set } = GetWebSet()

    React.useEffect(() => {
        const mo = localStorage.getItem('theme')
        if (mo === 'light' || mo === 'dark') {
            if (mo === 'light') {
                setMode(color.light)
            } else {
                setMode(color.dark)
            }
        } else {
            setMode(color.dark)
        }
    }, [])

    var color = {
        dark: {
            bg: '#161d25',
            heading: "#fefffe",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#fefffe',
            btntext: "#161d25",
            shadowcolor: "white",
            twoboxcolor: "#252d34"
        },
        light: {
            bg: "#fefeff",
            heading: "#212b36",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#212b36',
            btntext: "#fefeff",
            shadowcolor: "black",
            twoboxcolor: "#f8fafa"
        }
    }

    async function getPages() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/page/get",
            post: false,
            admin: false,
            token_user: ""
        })
        setPages(resp.data.data)
    }

    React.useEffect(() => {
        getPages()
    }, [])

    return (
        <Container maxWidth='lg'>
            {pages ?
                <Box pb={10} pt={10}>
                    <Grid justifyContent={'space-between'} container direction={'row'}>
                        <Grid item xs={12} lg={6} sm={6}>
                            <Stack direction={'column'} spacing={2}>
                                <Typography variant='h5' fontWeight={'bold'} >{data.home_footer_useful_link}</Typography>
                                <Stack direction={'column'} spacing={1}>
                                    {pages.map((i, key) => {
                                        return (
                                            <Typography
                                                sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                                component={Link}
                                                to={`/page/${i.slug}`}
                                                variant='body2' key={key} color={mode.textgray}>{i.title}</Typography>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            {web_set && <ButtonBase component={Link} to="/" >
                                <img src={`/images/${web_set.logo}`} style={{ width: 100, height: 100 }} />
                            </ButtonBase>}
                        </Grid>
                    </Grid>
                </Box> : <LinearProgress />}
        </Container>
    )
}

export default Footer
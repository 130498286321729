import { Box } from '@mui/system'
import React from 'react'
import { Button, Grid, Stack, Typography } from '@mui/material'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import "./lin.css"

const ManageModules = () => {
    const [data, setData] = React.useState("")

    function getData() {
        axios.get('http://verify-whatsham.oneoftheprojects.com/api/verify/api_modules')
            .then((res) => {
                if (res.data.success) {
                    setData(res.data.data)
                }
                else alert("Somethig went wron while fetching API modules. Please contact to the author.")
            })
            .catch((err) => console.log(err))
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <Box p={2}>
            <Grid spacing={2} container>
                {data ? data.map((i, key) => {
                    return (
                        <Grid key={key} xs={12} sm={3} lg={3} item>
                            <Box minHeight={'100%'} className="wrapper" p={2} borderRadius={2} >
                                <Helmet>
                                    <script>
                                        {`${i.js}`}
                                    </script>
                                </Helmet>
                                <Stack spacing={2} direction={'column'} alignItems='center'>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        fontWeight={'bold'} >{i.title}</Typography>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        color={'white'} variant='caption'>{i.description}</Typography>
                                    <Button
                                        fullWidth
                                        onClick={() => window.open(i.button)}
                                        size='small' color='inherit' variant='outlined' >Get</Button>
                                </Stack>
                            </Box>
                        </Grid>
                    )
                }) :
                    <>
                        <Grid xs={12} sm={3} lg={3} item>
                            <Box minHeight={'100%'} className="wrapper" p={2} borderRadius={2} >
                                <Stack spacing={2} direction={'column'} alignItems='center'>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        fontWeight={'bold'} >API Modules</Typography>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        color={'white'} variant='caption'>API module allows your users to send messages using REST API. Contact Author to add this module.</Typography>
                                    <Button
                                        fullWidth
                                        onClick={() => window.open("https://api.whatsapp.com/send/?phone=918430088300&text=Api+medule+for+WhatsHam&type=phone_number&app_absent=0")}
                                        size='small' color='inherit' variant='outlined' >Get</Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={3} lg={3} item>
                            <Box minHeight={'100%'} className="wrapper" p={2} borderRadius={2} >
                                <Stack spacing={2} direction={'column'} alignItems='center'>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        fontWeight={'bold'} >Virtual Numbers</Typography>
                                    <Typography
                                        style={{
                                            textShadowColor: 'black',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 10
                                        }}
                                        color={'white'} variant='caption'>Send bulkd whatsapp using virtual number api</Typography>
                                    <Button
                                        fullWidth
                                        onClick={() => window.open("https://api.whatsapp.com/send/?phone=918430088300&text=Api+medule+for+WhatsHam&type=phone_number&app_absent=0")}
                                        size='small' color='inherit' variant='outlined' >Get</Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}

export default ManageModules
import { Stack, Typography, CardMedia } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const UpdateApp = () => {
    return (
        <Box p={3}>
            <Typography align='center' fontWeight={'bold'} variant='h5' >Update from v1</Typography>
            <Typography align='center' color={'orange'} >Make sure to take a backup of your current deployed app in order to prevent any data lose before upgrading the app.</Typography>
            <Box mb={2} mt={2}>
                <Stack mb={2} spacing={4} direction={'column'}>
                    <CardMedia sx={{ borderRadius: 2 }} src="/documentation/bb1.jpg" component='img' height={"100%"} />
                    <CardMedia sx={{ borderRadius: 2 }} src="/documentation/bb2.jpg" component='img' height={"100%"} />
                    <CardMedia sx={{ borderRadius: 2 }} src="/documentation/bb3.jpg" component='img' height={"100%"} />
                    <CardMedia sx={{ borderRadius: 2 }} src="/documentation/bb4.jpg" component='img' height={"100%"} />
                    <CardMedia sx={{ borderRadius: 2 }} src="/documentation/bb5.jpg" component='img' height={"100%"} />
                </Stack>
            </Box>
            <Typography>This will let you to migrate your users to the version 2 however you will have to assign plans to them manually only for v2</Typography>
        </Box>
    )
}

export default UpdateApp
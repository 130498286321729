import React from 'react'
import { ManageBotContext } from '../../../../context/ManageBotContext'
import { Box, Stack, IconButton, LinearProgress, Typography } from '@mui/material'
import { Download, Delete } from '@mui/icons-material'
import { GlobalContext } from '../../../../context/GlobalContext'
import moment from 'moment'

const BotHistoryList = () => {
    const MANAGEBOT_CONTACT = React.useContext(ManageBotContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")

    async function getData() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/bot/get_running",
            token_user: token,
            admin: false,
            post: false
        })
        if (res.data.success) {
            MANAGEBOT_CONTACT.setData({ ...MANAGEBOT_CONTACT.data, bot_history: res.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])


    async function delBot(e) {
        if (window.confirm("Are you sure?")) {
            await GLOBAL_CONTEXT.hitAxios({
                path: "/api/bot/del",
                admin: false,
                post: true,
                obj: { id: e }
            })
            getData()
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                {
                    MANAGEBOT_CONTACT.data.bot_history ?
                        [...MANAGEBOT_CONTACT.data.bot_history].reverse().map((i, key) => {
                            return (
                                <Box key={key} boxShadow={5} borderRadius={4} p={2} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} >
                                    <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                        <Stack direction={'column'}>
                                            <Typography color={'white'} fontWeight={'bold'}>Timestamp: {moment(i.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                                            <Typography color='gray' textTransform={'capitalize'} variant='caption'>Status: {i.status}</Typography>
                                            <Typography color='gray' textTransform={'capitalize'} variant='caption'>Type: {i.templet_type}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1}>
                                            <IconButton
                                                onClick={() => delBot(i.id)}
                                                color='error' >
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        }) : <LinearProgress />}
            </Stack >
        </div>
    )
}

export default BotHistoryList
import { Button, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'

const InstallationCost = () => {
    return (
        <Box p={4}>
            <Typography sx={{ mb: 3 }} align='center' variant='h6' fontWeight={'bold'} >Installation Servcie</Typography>
            <Grid container spacing={2}>
                <Grid xs={6} lg={6} sm={6} item>
                    <Box borderRadius={4} bgcolor={'action.hover'}>
                        <Box bgcolor={'#F07800'} sx={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, p: 2 }}>
                            <Typography align='center' color={'white'} fontWeight='bold' >Installation</Typography>
                        </Box>
                        <Box p={2}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography align='center' variant='h5' >$30</Typography>
                                <Typography align='center' variant='body2'>New Installation</Typography>
                            </Stack>
                        </Box>
                        <a style={{ textDecoration: 'none' }} href='https://wa.me/918430088300' target="_blank" >
                            <Button sx={{ borderRadius: 4 }} variant='contained' fullWidth >Buy Now</Button>
                        </a>
                    </Box>
                </Grid>
                <Grid xs={6} lg={6} sm={6} item>
                    <Box borderRadius={4} bgcolor={'action.hover'}>
                        <Box bgcolor={'#F07800'} sx={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, p: 2 }}>
                            <Typography align='center' color={'white'} fontWeight='bold' >Updation</Typography>
                        </Box>
                        <Box p={2}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography align='center' variant='h5' >$25</Typography>
                                <Typography align='center' variant='body2'>Update App from older version</Typography>
                            </Stack>
                        </Box>
                        <a style={{ textDecoration: 'none' }} href='https://wa.me/918430088300' target="_blank" >
                            <Button sx={{ borderRadius: 4 }} variant='contained' fullWidth >Buy Now</Button>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default InstallationCost
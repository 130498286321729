import React from 'react'
import { Box, Typography, Divider, Stack, LinearProgress, Button } from '@mui/material'
import { TranslateContext } from '../../../context/TranslateContext'
import GetUserByToken from '../../../utils/GetUserByToken'
import { WhatsApp } from '@mui/icons-material'
import GetWebSet from '../../../utils/GetWebSet'
import PriceComp from '../../../frontend/components/PriceComp'

const MyPlan = () => {
    const { data } = React.useContext(TranslateContext)
    const { userdata } = GetUserByToken()
    const [state, setState] = React.useState({})
    const { web_set } = GetWebSet()
    return (
        <Box p={2}>
            {userdata && web_set ?
                userdata.plan ?
                    <>
                        <Stack direction={'row'} alignItems='center'>
                            <Typography fontWeight="bold" >{data.my_plan}</Typography>
                        </Stack>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        <Box mt={2} borderRadius={3} style={{
                            background: "linear-gradient(to top right, #000000 74%, orange 116%)"
                        }} p={2}>
                            <Stack direction={'row'} justifyContent='space-between' spacing={4}>
                                <Stack direction={'column'} spacing={2}>

                                    <WhatsApp sx={{ color: '#25D366', height: 100, width: 100 }} />

                                    <Typography color='gray' variant='caption' >{data.descriptio}: {JSON.parse(userdata.plan)?.description}</Typography>
                                </Stack>
                                <Stack alignItems={'flex-start'} direction={'column'}>
                                    <Typography color='orange' fontWeight={'bold'} >You Plan:-</Typography>
                                    <Typography color='gray' fontWeight={'bold'} >{JSON.parse(userdata.plan)?.name}</Typography>
                                    <Typography color='gray' variant='caption' >{data.msg_limit}: {JSON.parse(userdata.plan)?.message_limit}</Typography>
                                    <Typography style={{ textTransform: 'none', }} color='gray' variant='caption' >{data.msg_left} {new Intl.NumberFormat('en-US').format(userdata.left_msg)}</Typography>
                                    <Typography color='gray' variant='caption' >{data.allow_bot}: {JSON.parse(userdata.plan)?.allow_bot ? data.yes : data.no}</Typography>
                                    <Typography color='gray' variant='caption' >{data.home_plan_whatsapp_export}: {JSON.parse(userdata.plan)?.allow_group_bot ? data.yes : data.no}</Typography>
                                    <Typography color='gray' variant='caption' >{data.home_plan_instance_limit}: {JSON.parse(userdata.plan)?.instance_limit}</Typography>
                                    <Typography style={{ textTransform: 'none', }} color='gray' variant='caption' >{data.cost} {JSON.parse(userdata.plan)?.cost} {web_set.currency_symbol}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                    </> : null : <LinearProgress />}



            <PriceComp inner={true} />
        </Box>
    )
}

export default MyPlan
import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

export const CampaignContext = React.createContext(null)

export const CampaignProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_add: false,
        with_media: false,
        delay_in_sec: 10,
        campaign_done: false
    })
    const history = useHistory()

    return (
        <CampaignContext.Provider value={{ data, setData }}>
            {props.children}
        </CampaignContext.Provider>
    )
}


import { Delete, Download, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { CamTempContext } from '../../../../context/CamTempContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TranslateContext } from '../../../../context/TranslateContext'

const ExcelList = () => {
    const CAMTEMP_CONTACT = React.useContext(CamTempContext)
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")

    async function getExcelOne() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            token_user: token,
            post: false,
            admin: false,
            path: "/api/msgtemp/get_excel"
        })
        CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT, excel_templet_data: resp.data.data })
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getExcelOne()
    }, [token])

    const downloadFile = (e) => {

        delete e.id
        delete e.createdAt
        delete e.uid
        delete e.pastedString
        delete e.message
        e.excelJson = JSON.parse(e.excelJson)

        // create file in browser
        const fileName = e.name;
        const json = JSON.stringify(e, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    let myExcelXML = (function () {
        let Workbook, WorkbookStart = '<?xml version="1.0"?><ss:Workbook  xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">';
        const WorkbookEnd = '</ss:Workbook>';
        let fs, SheetName = 'SHEET 1',
            styleID = 1, columnWidth = 80,
            fileName = "whatsham" + Date.now(), uri, link;

        class myExcelXML {
            constructor(o) {
                let respArray = JSON.parse(o);
                let finalDataArray = [];

                for (let i = 0; i < respArray.length; i++) {
                    finalDataArray.push(flatten(respArray[i]));
                }

                let s = JSON.stringify(finalDataArray);
                fs = s.replace(/&/gi, '&amp;');
            }

            downLoad() {
                const Worksheet = myXMLWorkSheet(SheetName, fs);

                WorkbookStart += myXMLStyles(styleID);

                Workbook = WorkbookStart + Worksheet + WorkbookEnd;

                uri = 'data:text/xls;charset=utf-8,' + encodeURIComponent(Workbook);
                link = document.createElement("a");
                link.href = uri;
                link.style = "visibility:hidden";
                link.download = fileName + ".xls";

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            get fileName() {
                return fileName;
            }

            set fileName(n) {
                fileName = n;
            }

            get SheetName() {
                return SheetName;
            }

            set SheetName(n) {
                SheetName = n;
            }

            get styleID() {
                return styleID;
            }

            set styleID(n) {
                styleID = n;
            }
        }

        const myXMLStyles = function (id) {
            let Styles = '<ss:Styles><ss:Style ss:ID="' + id + '"><ss:Font ss:Bold="1"/></ss:Style></ss:Styles>';

            return Styles;
        }

        const myXMLWorkSheet = function (name, o) {
            const Table = myXMLTable(o);
            let WorksheetStart = '<ss:Worksheet ss:Name="' + name + '">';
            const WorksheetEnd = '</ss:Worksheet>';

            return WorksheetStart + Table + WorksheetEnd;
        }

        const myXMLTable = function (o) {
            let TableStart = '<ss:Table>';
            const TableEnd = '</ss:Table>';

            const tableData = JSON.parse(o);

            if (tableData.length > 0) {
                const columnHeader = Object.keys(tableData[0]);
                let rowData;
                for (let i = 0; i < columnHeader.length; i++) {
                    TableStart += myXMLColumn(columnWidth);

                }
                for (let j = 0; j < tableData.length; j++) {
                    rowData += myXMLRow(tableData[j], columnHeader);
                }
                TableStart += myXMLHead(1, columnHeader);
                TableStart += rowData;
            }

            return TableStart + TableEnd;
        }

        const myXMLColumn = function (w) {
            return '<ss:Column ss:AutoFitWidth="0" ss:Width="' + w + '"/>';
        }


        const myXMLHead = function (id, h) {
            let HeadStart = '<ss:Row ss:StyleID="' + id + '">';
            const HeadEnd = '</ss:Row>';

            for (let i = 0; i < h.length; i++) {
                const Cell = myXMLCell(h[i].toUpperCase());
                HeadStart += Cell;
            }

            return HeadStart + HeadEnd;
        }

        const myXMLRow = function (r, h) {
            let RowStart = '<ss:Row>';
            const RowEnd = '</ss:Row>';
            for (let i = 0; i < h.length; i++) {
                const Cell = myXMLCell(r[h[i]]);
                RowStart += Cell;
            }

            return RowStart + RowEnd;
        }

        const myXMLCell = function (n) {
            let CellStart = '<ss:Cell>';
            const CellEnd = '</ss:Cell>';

            const Data = myXMLData(n);
            CellStart += Data;

            return CellStart + CellEnd;
        }

        const myXMLData = function (d) {
            let DataStart = '<ss:Data ss:Type="String">';
            const DataEnd = '</ss:Data>';

            return DataStart + d + DataEnd;
        }

        const flatten = function (obj) {
            var obj1 = JSON.parse(JSON.stringify(obj));
            const obj2 = JSON.parse(JSON.stringify(obj));
            if (typeof obj === 'object') {
                for (var k1 in obj2) {
                    if (obj2.hasOwnProperty(k1)) {
                        if (typeof obj2[k1] === 'object' && obj2[k1] !== null) {
                            delete obj1[k1]
                            for (var k2 in obj2[k1]) {
                                if (obj2[k1].hasOwnProperty(k2)) {
                                    obj1[k1 + '-' + k2] = obj2[k1][k2];
                                }
                            }
                        }
                    }
                }
                var hasObject = false;
                for (var key in obj1) {
                    if (obj1.hasOwnProperty(key)) {
                        if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                            hasObject = true;
                        }
                    }
                }
                if (hasObject) {
                    return flatten(obj1);
                } else {
                    return obj1;
                }
            } else {
                return obj1;
            }
        }

        return myExcelXML;
    })();

    async function delOne(e) {
        if (window.confirm("Are you sure?")) {
            GLOBAL_CONTEXT.hitAxios({
                path: "/api/msgtemp/del",
                post: true,
                admin: false,
                obj: { id: e }
            })
        }
        getExcelOne()
    }

    return (
        <Stack direction={'column'} spacing={2} >
            {CAMTEMP_CONTACT.data.excel_templet_data ? CAMTEMP_CONTACT.data.excel_templet_data.map((i, key) => {
                return (
                    <Accordion
                        sx={{ boxShadow: 4, background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }}
                        key={key} >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Stack direction={'column'}>
                                <Typography color={'white'}>{i.name}</Typography>
                                <Typography fontSize={10} color={'gray'}>{data.templet_id}: {i.id}</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={'row'} justifyContent='space-between' spacing={2}>
                                <Stack alignItems={'flex-start'} direction={'column'} spacing={0.5}>
                                    <Button
                                        onClick={() => downloadFile(i)}
                                        color='secondary' size='small' >{data.download_content}</Button>
                                    <Button
                                        onClick={() => {
                                            var myTestXML = new myExcelXML(i.excelJson);
                                            myTestXML.downLoad()
                                        }}
                                        color='info' size='small' >{data.down_xlx}</Button>
                                </Stack>
                                <IconButton
                                    onClick={() => delOne(i.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )
            }) : null}
        </Stack>
    )
}

export default ExcelList
import { DarkMode, Language, LightMode, Login, Translate } from '@mui/icons-material'
import { AppBar, Button, ButtonBase, CardMedia, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import axios from 'axios'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import GetWebSet from '../../utils/GetWebSet'


const Header = () => {
    const history = useHistory()
    const [state, setState] = React.useState({})
    const { web_set } = GetWebSet()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function getLang() {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/translate/get_lang`)
            .then((res) => {
                setState({ ...state, langs: res.data.data })
            }).catch((err) => console.log(err))
    }

    React.useEffect(() => {
        getLang()
    }, [])

    function getMode() {
        const mo = localStorage.getItem('theme')
        if (mo === 'light' || mo === 'dark') {
            if (mo === 'light') {
                return ('light')
            } else {
                return ('dark')
            }
        } else {
            return ('dark')
        }
    }

    return (
        <AppBar
            component="div"
            position="fixed"
            indicatorColor="primary"
            sx={{ bgcolor: "rgba(33, 43, 54, 0.12)", backdropFilter: "blur(7px)" }}
        >
            <Container maxWidth='lg'>

                <Stack mt={2} mb={2} direction='row' alignItems={'center'} pr={1} justifyContent={'space-between'} >
                    {web_set && <ButtonBase component={Link} to="/" >
                        <img src={`/images/${web_set.logo}`} style={{ width: 50, height: 50 }} />
                    </ButtonBase>}
                    <Stack spacing={1} direction={'row'}>

                        {state.langs &&
                            <div>
                                <IconButton aria-describedby={id} onClick={handleClick}>
                                    <Translate />
                                </IconButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    {/* <Stack direction={'column'}> */}
                                    <List>
                                        {state.langs.map((i, key) => {
                                            return (
                                                <ListItem key={key} disablePadding>
                                                    <ListItemButton onClick={() => {
                                                        localStorage.setItem('language', i.slice(0, i.indexOf('.')))
                                                        window.location.reload()
                                                    }} >
                                                        <ListItemIcon>
                                                            <Language />
                                                        </ListItemIcon>
                                                        <ListItemText primary={i.slice(0, i.indexOf('.'))} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                    {/* </Stack> */}
                                </Popover>
                            </div>}

                        {getMode() === 'light' ?
                            <IconButton onClick={() => {
                                localStorage.setItem('theme', 'dark')
                                window.location.reload()
                            }} >
                                <DarkMode />
                            </IconButton>
                            : <IconButton onClick={() => {
                                localStorage.setItem('theme', 'light')
                                window.location.reload()
                            }} >
                                <LightMode />
                            </IconButton>}

                        <IconButton component={Link} to="/user/login" >
                            <Login />
                        </IconButton>
                    </Stack>
                </Stack>
            </Container>
        </AppBar>
    )
}

export default Header
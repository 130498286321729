import React from 'react'
import { Grid, Box, Container, Slide, Typography, TextField, Zoom, Button, LinearProgress } from '@mui/material'
import { Stack } from '@mui/system'
import axios from 'axios'
import { GlobalContext } from './context/GlobalContext'

const InstallAppScreen = () => {
    const [install, setInstall] = React.useState("")
    const [state, setState] = React.useState({
    })
    const [step, setStep] = React.useState(0)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    function intro() {
        setStep(0)
        setTimeout(() => {
            setStep(10)
        }, 2500);
        setTimeout(() => {
            setStep(1)
        }, 3500);
        setTimeout(() => {
            setStep(10)
        }, 6000);
        setTimeout(() => {
            setStep(2)
        }, 7000);
    }

    React.useEffect(() => {
        intro()
        checkIns()
    }, [])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function checkIns() {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/check_install`)
            .then((res) => {
                if (res.data.success) {
                    setInstall("yes")
                } else {
                    setInstall("no")
                }
            }).catch((err) => console.log(err))
    }

    async function installApp() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            admin: false,
            post: true,
            path: "/api/web/install_app",
            obj: state
        })
        if (resp.data.success) {
            setTimeout(() => {
                window.location.replace('/')
            }, 3000);
        }
    }


    return (
        install ?
            install === "yes" ?
                window.location.replace('/') :
                <Grid alignItems={'center'} justifyContent='center' container minHeight={'100vh'}>
                    <Grid item>
                        <Container maxWidth='lg'>
                            {step === 0 &&

                                <Slide timeout={2000} mountOnEnter unmountOnExit direction="up" in={step === 0} >
                                    <Box >
                                        <Stack direction={'column'} spacing={2}>
                                            <Typography align='center' variant='h2' >Love from the Developer side ❤️</Typography>
                                        </Stack>
                                    </Box>
                                </Slide>
                            }

                            {step === 1 &&

                                <Slide timeout={2000} mountOnEnter unmountOnExit direction="up" in={step === 1} >
                                    <Box >
                                        <Stack direction={'column'} spacing={2}>
                                            <Typography align='center' variant='h2' >Thank you for purchasing WhatsHam</Typography>
                                        </Stack>
                                    </Box>
                                </Slide>
                            }

                            {step === 2 &&

                                <Slide timeout={2000} mountOnEnter unmountOnExit direction="up" in={step === 2} >
                                    <Box >
                                        <Stack direction={'column'} spacing={2}>
                                            <Typography align='center' variant='h2' >Lets Start the installation</Typography>
                                            <TextField onChange={(e) => {
                                                setState({ ...state, app_name: e.target.value })
                                            }} size='small' fullWidth label="App Name" />
                                            <TextField
                                                helperText="Entry your currency rate i.e: how much of your currency = 1USD" onChange={(e) => {
                                                    setState({ ...state, exchange_rate: e.target.value })
                                                }} size='small' fullWidth label="Exchange Rate" />
                                            <TextField
                                                helperText="i.e: $/₹ etc"
                                                onChange={(e) => {
                                                    setState({ ...state, currency_symbol: e.target.value })
                                                }} size='small' fullWidth label="Currency Symbol" />
                                            <TextField onChange={(e) => {
                                                setState({ ...state, admin_email: e.target.value })
                                            }} size='small' fullWidth label="Admin Email" />
                                            <TextField onChange={(e) => {
                                                setState({ ...state, admin_password: e.target.value })
                                            }} size='small' fullWidth label="Admin Password" />
                                            <Button
                                                disabled={state.app_name && validateEmail(state.admin_email) && state.admin_password && state.exchange_rate && state.currency_symbol ? false : true}
                                                onClick={() => setStep(3)}
                                                fullWidth variant='outlined' >Next</Button>
                                        </Stack>
                                    </Box>
                                </Slide>
                            }

                            {step === 3 &&

                                <Zoom timeout={1000} in={step === 3} >
                                    <Box >
                                        <Stack direction={'column'} spacing={2}>
                                            <Typography align='center' variant='h2' >App Verification</Typography>
                                            <TextField onChange={(e) => setState({ ...state, purchase_code: e.target.value })} size='small' fullWidth label="Enter Purchase Code" />
                                            <Button
                                                disbaled={state.purchase_code ? false : true}
                                                onClick={installApp}
                                                fullWidth variant='outlined' >Lest Install whatsham</Button>
                                        </Stack>
                                    </Box>
                                </Zoom>
                            }
                        </Container>
                    </Grid>
                </Grid>
            : <LinearProgress />
    )
}

export default InstallAppScreen
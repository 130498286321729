import React from 'react'
import { Box, Button, Chip, Dialog, Divider, Grid, IconButton, InputAdornment, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import { Add, Close, Delete } from '@mui/icons-material'
import { Container } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'

const ListTemp = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const lang = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        dialog: false,
        dialog: false
    })
    const [sec, setSec] = React.useState("")
    const [row, setRow] = React.useState([])
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const [data, setData] = React.useState("")

    const [rowText, setRowText] = React.useState("")

    const [sections, setSection] = React.useState([])
    const [listMessage, setListMessage] = React.useState({
        text: "",
        footer: "",
        title: "",
        buttonText: ""
    })

    function remRow(e) {
        const data = row.filter(i => i.rowId !== e)
        setRow(data)
    }

    function remSec(e) {
        const data = sections.filter(i => i !== e)
        setSection(data)
    }

    async function addNewlist() {
        setState({ ...state, loading: true })
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/listtemp/add",
            post: true,
            admin: false,
            obj: {
                text: listMessage.title,
                footer: listMessage.footer,
                title: listMessage.title,
                buttonText: listMessage.buttonText,
                section: sections
            }
        })
        setState({ ...state, loading: false, dialog: false })
        setSection([])
        setListMessage({
            text: "",
            footer: "",
            title: "",
            buttonText: ""
        })
        getListData()
    }

    async function getListData() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/listtemp/get",
            post: false,
            admin: false,
            token_user: token
        })
        if (resp.data.success) {
            setData(resp.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getListData()
    }, [token])

    async function delBtn(e) {
        if (window.confirm("Are your sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/listtemp/del",
                post: true,
                admin: false,
                obj: { id: e }
            })
            getListData()
        }
    }

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{lang.data.mng_list_temp}</Typography>
                <Button
                    onClick={() => setState({ ...state, dialog: true })}
                    size='small' variant='outlined'>{lang.data.add_new_btn}</Button>
            </Stack>
            <Box mt={2} >
                <Divider />
            </Box>

            <Dialog fullScreen open={state.dialog} >
                <Box mb={2} p={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })}>
                        <Close />
                    </IconButton>
                </Box>
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={2}>
                        <TextField onChange={(e) => {
                            setListMessage({ ...listMessage, title: e.target.value })
                        }} label={lang.data.title} fullWidth size='small' />
                        <TextField onChange={(e) => {
                            setListMessage({ ...listMessage, footer: e.target.value })
                        }} label={lang.data.footer} fullWidth size='small' />
                        <TextField
                            multiline
                            rows={3}
                            onChange={(e) => {
                                setListMessage({ ...listMessage, text: e.target.value })
                            }} label={lang.data.text} fullWidth size='small' />
                        <TextField onChange={(e) => {
                            setListMessage({ ...listMessage, buttonText: e.target.value })
                        }} label={lang.data.btn_textt} fullWidth size='small' />

                        {sections.length > 0 ?
                            <Stack direction={'row'} spacing={2}>
                                {sections.map((i, key) => {
                                    return (
                                        <Chip onDelete={() => remSec(i)} key={key} label={i.title} />
                                    )
                                })}
                            </Stack>
                            : <Typography variant='caption' color={'gray'}>{lang.data.no_sec_added_yet}</Typography>}

                        <Stack direction={'row'}>
                            <Button
                                startIcon={<Add />}
                                onClick={() => setState({ ...state, dialog_two: true })}
                                size='small' variant='outlined'>{lang.data.add_list_sec}</Button>
                        </Stack>
                        <Divider />

                        <LoadingButton
                            loading={state.loading}
                            onClick={addNewlist}
                            disabled={listMessage.title && listMessage.footer && listMessage.text && listMessage.buttonText && sections?.length > 0 ? false : true}
                            variant='contained'
                            size='small'
                        >{lang.data.save_temp}</LoadingButton>
                    </Stack>
                </Container>
            </Dialog>

            <Dialog onClose={() => setState({ ...state, dialog_two: false })} fullWidth open={state.dialog_two} >
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography fontWeight={'bold'}>{lang.data.add_sec}</Typography>
                        <TextField
                            onChange={(e) => setSec(e.target.value)}
                            size='small' label={lang.data.sec_title} fullWidth />
                        {row.length > 0 ?
                            <Stack direction={'row'} spacing={1} >
                                {row.map((i, key) => {
                                    return (
                                        <Chip
                                            onDelete={() => remRow(i.rowId)}
                                            mb={2} key={key} label={i.title} />
                                    )
                                })}
                            </Stack>
                            : <Typography color={'gray'} variant='caption'>{lang.data.op_otn_added_ye}</Typography>}
                        <TextField
                            value={rowText}
                            onChange={(e) => setRowText(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        disabled={rowText ? false : true}
                                        onClick={() => {
                                            setRow([...row, { rowId: Date.now(), title: rowText }])
                                            setRowText("")
                                        }}
                                    >
                                        <Add />
                                    </IconButton>
                                </InputAdornment>
                            }}
                            size='small' label={lang.data.write_an_opt} fullWidth />

                        <Button
                            onClick={() => {
                                setSection([...sections, {
                                    title: sec,
                                    rows: row
                                }])
                                setState({ ...state, dialog_two: false })
                                setRow([])
                            }}
                            disabled={sec && row.length > 0 ? false : true}
                            variant='contained' size='small' >{lang.data.add_this_sec}</Button>
                    </Stack>
                </Box>
            </Dialog>


            <Box mt={2}>
                <Grid container spacing={2}  >
                    {data && [...data]?.reverse().map((i, key) => {
                        return (
                            <Grid key={key} xs={6} sm={4} lg={3} item>
                                <Box height={'100%'} boxShadow={4} borderRadius={4} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} p={1}>
                                    <Stack alignItems={'center'} direction={'column'}>
                                        <Stack direction={'column'}>
                                            <Typography color={'white'}>{i.title}</Typography>
                                            <Typography align='center' fontSize={12} >{lang.data.templet_id}: {i.id}</Typography>
                                        </Stack>
                                        <Stack direction={'row'}>
                                            <IconButton
                                                onClick={() => delBtn(i.id)}
                                                color='secondary' >
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box >
    )
}

export default ListTemp
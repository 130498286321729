import { Close, WhatsApp } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, ImageList, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import React from 'react'
import { LoadingButton } from '@mui/lab'
import { ManageUserContext } from '../../../context/ManageUserContext'
import { GlobalContext } from '../../../context/GlobalContext'
import GetPlansPublic from '../../../utils/GetPlansPublic'
import GetUserByToken from '../../../utils/GetUserByToken'
import { TranslateContext } from '../../../context/TranslateContext'

const DialogComp = () => {
    const MANAGE_USER_CONTEXT = React.useContext(ManageUserContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const { plans } = GetPlansPublic()
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")
    const [state, setState] = React.useState({
        plan: {}
    })
    const [user, setUser] = React.useState("")

    React.useEffect(() => {
        if (!MANAGE_USER_CONTEXT.data.edit_user_data) {
            return
        }
        setUser(MANAGE_USER_CONTEXT.data.edit_user_data)
    }, [MANAGE_USER_CONTEXT.data.edit_user_data])



    async function getUser() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_all_user",
            post: false,
            admin: true,
            token_user: token
        })
        if (res.data.success) {
            MANAGE_USER_CONTEXT.setData({ ...MANAGE_USER_CONTEXT.data, user_data: res.data.data, dialog_user_edit: false })
        }
    }

    async function updatePlan() {
        setState({ ...state, loading: true })
        await GLOBAL_CONTEXT.hitAxios({
            post: true,
            path: "/api/user/admin_plan_update",
            admin: true,
            obj: {
                plan: MANAGE_USER_CONTEXT.data.edit_user_data?.plan,
                uid: MANAGE_USER_CONTEXT.data?.edit_user_data?.uid
            }
        })
        getUser()
        setState({ ...state, loading: false })
    }

    async function userUpdate() {
        setState({ ...state, loading: true })
        var a = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/admin_user_update",
            post: true,
            admin: true,
            obj: {
                name: user.name,
                email: user.email.toLowerCase(),
                mobile: user.mobile,
                new_password: user.new_password,
                id: user.id,
                uid: user.uid
            }
        })
        getUser()
        setState({ ...state, loading: false })
    }

    async function delUser(e) {
        if (window.confirm('Are you sure?')) {
            var res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/admin/del_user",
                admin: true,
                post: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getUser()
            }
        }
    }


    return (
        <Dialog fullScreen open={MANAGE_USER_CONTEXT.data.dialog_user_edit}>
            <Box p={2}>
                <IconButton onClick={() => MANAGE_USER_CONTEXT.setData({ ...MANAGE_USER_CONTEXT.data, dialog_user_edit: false })} >
                    <Close />
                </IconButton>
            </Box>
            <Container maxWidth='lg'>
                {user &&
                    <Stack direction={'column'} spacing={2}>
                        <TextField
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                            label={data.name} fullWidth size='small' value={user?.name} />
                        <TextField
                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                            label={data.email} fullWidth size='small' value={user?.email} />
                        <TextField
                            onChange={(e) => setUser({ ...user, mobile: e.target.value })}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+</InputAdornment>,
                            }}
                            type={'number'} label={data.mobile} fullWidth size='small' value={user?.mobile} />
                        <TextField
                            onChange={(e) => setUser({ ...user, new_password: e.target.value })}
                            label={data.password} fullWidth size='small' helperText={data.leavr_it_blank} />
                        <LoadingButton
                            loading={state.loading}
                            onClick={userUpdate}
                            disabled={user.name && user.email && user.mobile ? false : true}
                            fullWidth variant='contained' size='small' >{data.save}</LoadingButton>

                        <LoadingButton
                            loading={state.loading}
                            onClick={() => delUser(user.id)}
                            fullWidth variant='outlined' color='error' size='small' >{data.delete_user}</LoadingButton>



                        <Box borderRadius={2} border={0.5} p={2}>
                            <Box bgcolor={'gray'} borderRadius={2} p={1}>
                                <Typography align='center' color='black' fontWeight={'bold'} >{data.manage_plan}</Typography>
                            </Box>
                            <ImageList
                                sx={{
                                    gridAutoFlow: "column",
                                    gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                    gridAutoColumns: "minmax(160px, 1fr)",
                                    msOverflowStyle: 'none',
                                    overflowX: 'scroll',
                                    '::-webkit-scrollbar': {
                                        width: '10px'
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: '#4F4F4F',
                                        borderRadius: 10
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: "#1B1B1B",
                                        borderRadius: 10
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: 'green',
                                    }
                                }}
                            >
                                {
                                    plans ? (
                                        <Stack mb={2} spacing={4} direction={'row'}>
                                            {
                                                plans?.map((i, key) => {
                                                    return (
                                                        <Box
                                                            onClick={() => {
                                                                MANAGE_USER_CONTEXT.setData({ ...MANAGE_USER_CONTEXT.data, edit_user_data: { ...MANAGE_USER_CONTEXT.data.edit_user_data, plan: JSON.stringify(i) } })
                                                            }}
                                                            boxShadow={5} border={MANAGE_USER_CONTEXT.data.edit_user_data.plan && i.id === JSON.parse(MANAGE_USER_CONTEXT.data.edit_user_data?.plan)?.id ? 3 : 0} borderColor="orange" p={4} key={key} borderRadius={3} style={{
                                                                background: "linear-gradient(to top right, #000000 74%, green 116%)", cursor: 'pointer'
                                                            }} >
                                                            <Stack height={'100%'} spacing={1} alignItems={'center'} direction={'column'}>
                                                                <WhatsApp sx={{ color: '#25D366', height: 100, width: 100 }} />
                                                                <Typography align='center' color='white' fontWeight={'bold'} >{i.name}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.message_limit} {data.home_plan_message_limit}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.cost}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.allow_bot ? data.bots : data.nobots}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.allow_group_export ? data.user_dash_menu_export_grp : data.no_grp_export}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.instance_limit} {data.home_plan_instance_limit}</Typography>
                                                                <Typography align='center' style={{ textTransform: 'none', }} color='gray' variant='caption' >{i.description}</Typography>
                                                            </Stack>
                                                        </Box>
                                                    )
                                                })
                                            }

                                        </Stack>
                                    ) : <Box mt={2} mb={2}>
                                        <LinearProgress />
                                    </Box>
                                }
                            </ImageList>
                            <LoadingButton
                                loading={state.loading}
                                disabled={state.plan ? false : true}
                                onClick={updatePlan}
                                variant='contained' size='small' color='secondary' fullWidth >{data.update_plan}</LoadingButton>
                        </Box>

                    </Stack>}
            </Container>
        </Dialog>
    )
}

export default DialogComp
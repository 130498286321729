import { Delete, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { CamTempContext } from '../../../../context/CamTempContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TranslateContext } from '../../../../context/TranslateContext'

const PastedList = () => {
    const CAMTEMP_CONTACT = React.useContext(CamTempContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")

    async function getPastedOne() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            token_user: token,
            post: false,
            admin: false,
            path: "/api/msgtemp/get_paste"
        })
        CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT, pasted_string: resp.data.data })
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getPastedOne()
    }, [token])

    const downloadFile = (e) => {

        delete e.excelJson
        delete e.id
        delete e.createdAt
        delete e.uid

        // create file in browser
        const fileName = e.name;
        const json = JSON.stringify(e, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async function delOne(e) {
        if (window.confirm("Are you sure?")) {
            GLOBAL_CONTEXT.hitAxios({
                path: "/api/msgtemp/del",
                post: true,
                admin: false,
                obj: { id: e }
            })
        }
        getPastedOne()
    }

    return (
        <Stack direction={'column'} spacing={2} >
            {CAMTEMP_CONTACT.data.pasted_string ? CAMTEMP_CONTACT.data.pasted_string.map((i, key) => {
                return (
                    <Accordion
                        sx={{ boxShadow: 4, background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }}
                        key={key} >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Stack direction={'column'}>
                                <Typography color={'white'}>{i.name}</Typography>
                                <Typography fontSize={12} color={'gray'}>{data.templet_id}: {i.id}</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={'row'} justifyContent='space-between' spacing={2}>
                                <Button
                                    onClick={() => downloadFile(i)}
                                    color='secondary' size='small' >{data.download_content}</Button>
                                <IconButton
                                    onClick={() => delOne(i.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )
            }) : null}
        </Stack>
    )
}

export default PastedList
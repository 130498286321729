import React from 'react'
import { Box, Typography, } from '@mui/material'

const TransDoc = () => {
    return (
        <Box pb={4} >
            <Typography align='center' fontWeight={'bold'} variant='h5' >Translate</Typography>


            <Box mb={2} mt={2} >
                <Typography fontSize={15}  >Go to the root folder => translate you will find all translation files add yours</Typography>
                <Typography fontSize={15} color="orange"  >Warning:- Do not delete the en.json translation file</Typography>
            </Box>

        </Box>
    )
}

export default TransDoc
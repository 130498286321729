import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { ManageUserContext } from '../../../context/ManageUserContext'
import DataTableUser from './DataTableUser'
import DialogComp from './DialogComp'

const ManageUserInit = () => {
    const MANAGE_USER_CONTEXT = React.useContext(ManageUserContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    return (
        <div>
            <DataTableUser />
            <DialogComp />
        </div>
    )
}

export default ManageUserInit
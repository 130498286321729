import axios from 'axios'
import React from 'react'

const GetInstanceUser = () => {
    const [instance, setInstance] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    React.useEffect(() => {
        if (!token) {
            return
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/instance/get_instance_uid`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => setInstance(res.data.data))
            .catch((err) => console.log(err))
    }, [token])
    return { instance }
}

export default GetInstanceUser
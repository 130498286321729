import React from 'react'
import { Box, Divider, Stack, Typography, Button, CircularProgress } from '@mui/material'
import { ManageBotContext } from '../../../context/ManageBotContext'
import DialogMain from './components/DialogMain'
import BotHistoryList from './components/BotHistoryList'
import { TranslateContext } from '../../../context/TranslateContext'
import GetUserByToken from '../../../utils/GetUserByToken'

const ManageBotInit = () => {
    const MANAGEBOT_CONTACT = React.useContext(ManageBotContext)
    const { data } = React.useContext(TranslateContext)
    const { userdata } = GetUserByToken()

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{data.manage_bot}</Typography>
                {userdata ?
                    JSON.parse(userdata?.plan)?.allow_bot ?
                        <Button
                            onClick={() => MANAGEBOT_CONTACT.setData({ ...MANAGEBOT_CONTACT.data, dialog: true })}
                            size='small' variant='outlined'>{data.add_new_btn}</Button>
                        :
                        <Button
                            disabled={true}
                            size='small' variant='outlined'>{data.not_alloed_in_plan}</Button>
                    : <CircularProgress />}
            </Stack>
            <Box mb={2} mt={2} >
                <Divider />
            </Box>
            <DialogMain />

            <BotHistoryList />
        </Box>
    )
}

export default ManageBotInit
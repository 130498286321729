import { Grid, Container, Typography, ButtonBase } from '@mui/material'
import ReactPlayer from 'react-player/youtube'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'

const ThreeF = ({ mode, web_set }) => {
    const [im, setIm] = React.useState(0)
    const { data } = React.useContext(TranslateContext)
    const arr = [{
        title: data.home_feature_mul_ins,
        des: data.home_feature_mul_ins_des
    }, {
        title: data.home_feature_atch,
        des: data.home_feature_atch_des
    }, {
        title: data.home_feature_btn,
        des: data.home_feature_btn_des
    }, {
        title: data.home_feature_list,
        des: data.home_feature_list_des
    }, {
        title: data.home_feature_cam,
        des: data.home_feature_cam_des
    }, {
        title: data.home_feature_bot,
        des: data.home_feature_bot_des
    }, {
        title: data.home_feature_grp_grab,
        des: data.home_feature_grp_grab_des
    }, {
        title: data.home_feature_ping,
        des: data.home_feature_ping_des
    }]

    const img = [{
        name: "/assets/multiple_wa.png"
    }, {
        name: "/assets/atch.png"
    }, {
        name: "/assets/btn.png"
    }, {
        name: "/assets/list.png"
    }, {
        name: "/assets/campaign.png"
    }, {
        name: "/assets/botmsg.png"
    }, {
        name: "/assets/grp.png"
    }, {
        name: "/assets/pingtoadmin.png"
    }]

    return (
        <Box sx={{ backgroundImage: `url(${img[im].name})`, }} >
            <Box sx={{ bgcolor: mode.bg === "#161d25" ? "rgba(2, 18, 37, 0.67)" : "rgba(243, 243, 243, 0.66)", backdropFilter: "blur(10px)", }} pt={10} pb={10}>
                <Container maxWidth='lg' >
                    <Stack alignItems={'center'} mb={4} >
                        <Typography variant='h4' fontWeight={'bold'}>{data.home_need_toKnow} <a style={{ color: mode.orange }}>{web_set.app_name}</a></Typography>
                        <Typography sx={{ textShadow: `-1px 1px 10px ${mode.shadowcolor === "white" ? "black" : "white"}` }} color={mode.textgray} variant='body'>{data.home_need_toKnow_des}</Typography>
                    </Stack>

                    <Box mb={5} mt={3}>
                        <Grid container alignItems={'center'} justifyContent='center'>
                            <Grid item xs={12} sm={6} lg={6}>
                                {web_set && <ReactPlayer width={'100%'} url={web_set.home_video} />}
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid direction={'row'} container spacing={4} justifyContent='space-between'>
                        <Grid xs={12} sm={6} lg={6} item>
                            <Stack direction={'column'} spacing={3}>
                                <Stack alignItems={'flex-start'} spacing={2} direction={'column'}>
                                    {arr.slice(0, 4).map((i, key) => {
                                        return (
                                            <ButtonBase
                                                onClick={() => {
                                                    setIm(key)
                                                }}
                                                component='box' sx={{
                                                    cursor: 'pointer', bgcolor: mode.twoboxcolor, p: 2, borderRadius: 2, width: "100%",
                                                    justifyContent: "flex-start",
                                                    border: key === im ? 1 : 0, borderColor: mode.heading
                                                }} key={key} >
                                                <Stack alignItems={'flex-start'} spacing={1} direction={'column'}>
                                                    <Typography variant='body' fontWeight={'bold'} >{i.title}</Typography>
                                                    <Typography variant='body2' color={mode.textgray}>{i.des}</Typography>
                                                </Stack>
                                            </ButtonBase>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} lg={6} item>

                            <Stack direction={'column'} spacing={3}>
                                <Stack alignItems={'flex-start'} spacing={2} direction={'column'}>
                                    {arr.slice(4, 8).map((i, key) => {
                                        return (
                                            <ButtonBase
                                                onClick={() => {
                                                    setIm(key + 4)
                                                }}
                                                component='box' sx={{
                                                    cursor: 'pointer', bgcolor: mode.twoboxcolor, p: 2, borderRadius: 2, width: "100%",
                                                    justifyContent: "flex-start",
                                                    border: key + 4 === im ? 1 : 0, borderColor: mode.heading
                                                }} key={key} >
                                                <Stack alignItems={'flex-start'} spacing={1} direction={'column'}>
                                                    <Typography variant='body' fontWeight={'bold'} >{i.title}</Typography>
                                                    <Typography variant='body2' color={mode.textgray}>{i.des}</Typography>
                                                </Stack>
                                            </ButtonBase>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default ThreeF
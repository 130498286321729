import React from 'react'
import { CampaignProvider } from '../../../context/CampaignContext'
import CamScInit from './components/CamScInit'

const CampaignScreen = () => {
    return (
        <CampaignProvider>
            <CamScInit />
        </CampaignProvider>
    )
}

export default CampaignScreen
import React from 'react'
import { Box, LinearProgress, Stack, TextField, Container, IconButton, Divider, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { DoneAll, Send, Done, ExpandMore } from '@mui/icons-material'
import axios from 'axios'
import moment from 'moment'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'


const PingToAdmin = () => {
    const [state, setState] = React.useState({})
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    async function sendMsg() {
        await GLOBAL_CONTEXT.hitAxios({
            path: "/api/ping/add",
            admin: false,
            post: true,
            obj: {
                message: state.message
            }
        })
        getMsg()
    }

    async function getMsg() {

        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/ping/my_message",
            post: false,
            admin: false,
            token_user: token
        })
        setState({ ...state, all_messages: res.data.data, loading: false, message: "" })

    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getMsg()
    }, [token])

    return (
        <Box p={2}>
            <Stack direction={'row'} alignItems='center'>
                <Typography fontWeight="bold" >{data.ping_to_admin}</Typography>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
            <Box mt={4} >
                <Stack alignItems={'flex-start'} direction={'column'} spacing={2}>
                    <TextField value={state.message} onChange={(e) => setState({ ...state, message: e.target.value })} label={data.typ_your_msg} size='small' fullWidth rows={4} multiline />
                    {
                        state.loading ? (
                            <CircularProgress />
                        ) : (
                            <IconButton onClick={sendMsg} disabled={state.message ? false : true} >
                                <Send />
                            </IconButton>
                        )
                    }
                </Stack>

                <Box mt={2} mb={2}>
                    <Divider />
                </Box>

                <Stack direction={'column'} spacing={2}>
                    {
                        state.all_messages ? [...state.all_messages].reverse().map((item, key) => {
                            return (
                                <Accordion key={key} >
                                    <AccordionSummary
                                        sx={{ bgcolor: item.admin_response ? "green" : null, borderRadius: 2 }}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant='caption' >{key + 1}) {item.message} {item.admin_response ? <DoneAll sx={{ color: 'lightgreen', height: 15, width: 15 }} /> : <Done sx={{ color: 'lightgreen', height: 15, width: 15 }} />}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Typography sx={{ mb: 2 }} fontSize={12} color='gray' >{
                                                item.admin_response ? (
                                                    item.admin_response
                                                ) : data.await_forres
                                            }</Typography>
                                            <Divider />
                                            <Typography sx={{ mt: 2 }} align='right' fontSize={12} color='gray' >Sent on: {moment(item.createdAt).format('DD-MMMM-YYYY')}</Typography>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) : <LinearProgress />
                    }
                </Stack>
            </Box>

        </Box >
    )
}

export default PingToAdmin
import React from 'react'
import { Box, Stack, Typography, Divider, TextField, LinearProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'
import { TranslateContext } from '../../context/TranslateContext'

const ProfileSet = () => {
    const [state, setState] = React.useState({})
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")
    const history = useHistory()
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    async function getEmail() {

        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/get_admin_email",
            post: false,
            admin: true,
            token_user: token
        })
        if (res.data.success) {

            setState({ ...state, email: res.data.email })
        }

    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getEmail()
    }, [token])

    async function updateProfile() {
        await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/change_admin_pw",
            admin: true,
            post: true,
            obj: state
        })
        getEmail()
    }

    return (
        <Box p={2} >
            <Box>
                <Stack justifyContent={'space-between'} alignItems='center' direction={'row'} spacing={2}>
                    <Typography fontWeight={'bold'} >{data.admin_profile}</Typography>
                </Stack>
            </Box>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            {
                state.email ? (
                    <Stack direction={'column'} spacing={2}>
                        <TextField onChange={(e) => setState({ ...state, email: e.target.value })} value={state.email} label={data.email} fullWidth size='small' />
                        <TextField onChange={(e) => setState({ ...state, new_password: e.target.value })} label={data.password} helperText={data.leavr_it_blank} fullWidth size='small' />
                        <LoadingButton loading={state.loading} onClick={updateProfile} size='small' fullWidth variant='contained' >{data.update_profile}</LoadingButton>
                    </Stack>
                ) : <LinearProgress />
            }
        </Box>
    )
}

export default ProfileSet
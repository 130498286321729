import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Divider, Stack, Typography, Tab, Button, IconButton } from '@mui/material'
import React from 'react'
import { CamTempContext } from '../../../context/CamTempContext'
import PasteDialog from './components/PasteDialog'
import { Close } from '@mui/icons-material'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'
import PastedList from './components/PastedList'
import ExcelDialog from './components/ExcelDialog'
import ExcelList from './components/ExcelList'

const CamTInit = () => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const CAMTEMP_CONTACT = React.useContext(CamTempContext)
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({

    })

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Stack direction={'column'}>
                    <Typography fontWeight={'bold'} >{data.manage_cam_tem}</Typography>
                    <Typography variant='caption' color='orange' >{data.up_to250}</Typography>
                </Stack>
                {value === "1" ?
                    <Button
                        onClick={() => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, dialog: true })}
                        variant='outlined' sx={{ textTransform: 'none' }} >{data.add_new_btn}</Button> :
                    <Button
                        onClick={() => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, dialog_excel: true })}
                        variant='outlined' sx={{ textTransform: 'none' }} >{data.add_new_with_exl}</Button>}
            </Stack>
            <Box mt={2} >
                <Divider />
            </Box>

            <PasteDialog />
            <ExcelDialog />

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="a">
                        <Tab label={data.upload_past_num} value="1" />
                        <Tab label={data.upload_exl_temp} value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <PastedList />
                </TabPanel>
                <TabPanel value="2">
                    <ExcelList />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default CamTInit
import React from 'react'
import { BtnTempProvider } from '../../../context/BtnTempContext';
import BtnTempInit from './BtnTempInit';

const ButtonTemplet = () => {
    return (
        <BtnTempProvider>
            <BtnTempInit />
        </BtnTempProvider>
    )
}

export default ButtonTemplet
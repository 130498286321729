import React from 'react'
import UserDash from './pages/UserDash'
import ManageInstance from './pages/manage-instance/ManageInstance'
import CampaignScreen from './pages/campaign/CampaignScreen'
import ButtonTemplet from './pages/button-templet/ButtonTemplet'
import UploadMedia from './pages/upload-media/UploadMedia'
import ListTemp from './pages/list-templet/ListTemp'
import CampTemp from './pages/campaign-templet/CampTemp'
import CampaignHistory from './pages/campaign-history/CampaignHistory'
import ManageBot from './pages/manage-bot/ManageBot'
import GrabGroups from './pages/grab-grpups/GrabGroups'
import PingToAdmin from './pages/ping/PingToAdmin'
import UpdateProfile from './pages/profile/UpdateProfile'
import MyPlan from './pages/my-plan/MyPlan'

const UserDashPage = (props) => {
    return (
        <div>
            {props.page === 0 && <UserDash />}
            {props.page === 1 && <ManageInstance />}
            {props.page === 2 && <CampaignScreen />}
            {props.page === 3 && <UploadMedia />}
            {props.page === 4 && <ButtonTemplet />}
            {props.page === 5 && <ListTemp />}
            {props.page === 6 && <CampTemp />}
            {props.page === 7 && <CampaignHistory />}
            {props.page === 8 && <ManageBot />}
            {props.page === 9 && <GrabGroups />}
            {props.page === 10 && <PingToAdmin />}
            {props.page === 11 && <MyPlan />}
            {props.page === 12 && <UpdateProfile />}
        </div>
    )
}

export default UserDashPage
import { Box, Button, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { ManagePlansContext } from '../../../context/ManagePlansContext'
import { TranslateContext } from '../../../context/TranslateContext'
import DialogAdd from './DialogAdd'
import PlanList from './PlanList'

const ManagePlansInit = () => {
    const MANAGE_PLANS_CONTEXT = React.useContext(ManagePlansContext)
    const { data } = React.useContext(TranslateContext)
    return (
        <Box p={2}>

            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{data.manage_plan}</Typography>
                <Button
                    onClick={() => MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, dialog_add: true })}
                    size='small' variant='outlined' >{data.add_new_btn}</Button>
            </Stack>

            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <DialogAdd />
            <PlanList />

        </Box>
    )
}

export default ManagePlansInit
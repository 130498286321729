import axios from 'axios'
import React from 'react'

const GetPlansPublic = () => {
    const [plans, setPlan] = React.useState("")
    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/plan/get_all`).then((res) => setPlan(res.data.data))
            .catch((err) => console.log(err))
    }, [])
    return { plans }
}

export default GetPlansPublic
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Divider, Stack, Typography, Tab, Grid, Button } from '@mui/material'
import React from 'react'
import { CampaignContext } from '../../../../context/CampaignContext';
import ButtonNumbers from './types/ButtonNumbers';
import ExcelNumbers from './types/ExcelNumbers';
import ListNumbers from './types/ListNumbers';
import PastedNumbers from './types/PastedNumbers';
import { TranslateContext } from '../../../../context/TranslateContext'

const CamScInit = () => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const CAMPAIGN_CONTEXT = React.useContext(CampaignContext)
    const { data } = React.useContext(TranslateContext)

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Stack direction={'column'}>
                    <Typography fontWeight={'bold'} >{data.cam_start_cam}</Typography>
                    <Typography variant='caption' color={'gray'} >{data.cam_choose}</Typography>
                </Stack>
            </Stack>
            <Box mt={2} >
                <Divider />
            </Box>

            {CAMPAIGN_CONTEXT.data.campaign_done ?
                <Grid container minHeight={'70vh'} alignItems="center" justifyContent={'center'} >
                    <Grid item>
                        <Stack direction={'column'} spacing={2}>
                            <Typography color='green' fontWeight={'bold'} >{data.congrax}</Typography>
                            <Button
                                onClick={() => CAMPAIGN_CONTEXT.setData({ ...CAMPAIGN_CONTEXT.data, campaign_done: false })}
                                variant='outlined' color='secondary' >{data.go_bak}</Button>
                        </Stack>
                    </Grid>
                </Grid> :
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='scrollable' onChange={handleChange} aria-label="a">
                            <Tab label={data.send_pasted_temp} value="1" />
                            <Tab label={data.send_with_excel} value="2" />
                            <Tab label={data.send_with_btn} value="3" />
                            <Tab label={data.send_with_list} value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <PastedNumbers />
                    </TabPanel>
                    <TabPanel value="2">
                        <ExcelNumbers />
                    </TabPanel>
                    <TabPanel value="3">
                        <ButtonNumbers />
                    </TabPanel>
                    <TabPanel value="4">
                        <ListNumbers />
                    </TabPanel>
                </TabContext>}
        </Box>
    )
}

export default CamScInit
import React from 'react'
import { Box, Typography, Divider, Stack, Button, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DialogNoImg from './noimage/DialogNoImg';
import { BtnTempContext } from '../../../context/BtnTempContext';
import ListNoimg from './noimage/ListNoimg';
import DialogWithImg from './withimage/DialogWithImg';
import ListWithImg from './withimage/ListWithImg';
import { GlobalContext } from '../../../context/GlobalContext';
import { TranslateContext } from '../../../context/TranslateContext';

const BtnTempInit = () => {
    const [value, setValue] = React.useState('1');
    const BTNTEMP_CONTEXT = React.useContext(BtnTempContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{data.mng_btn_temp}</Typography>
                {value === "1" ?
                    <Button
                        onClick={() => BTNTEMP_CONTEXT.setData({ ...BTNTEMP_CONTEXT.data, dialog_noimg: true })}
                        sx={{ textTransform: 'none' }} size='small' variant='outlined'>{data.add_btn_text}</Button> :
                    <Button
                        onClick={() => BTNTEMP_CONTEXT.setData({ ...BTNTEMP_CONTEXT.data, dialog_withimg: true })}
                        sx={{ textTransform: 'none' }} size='small' variant='outlined'>{data.add_btn_with_im}</Button>}
            </Stack>
            <Box mt={2} >
                <Divider />
            </Box>

            <DialogNoImg />
            <DialogWithImg />

            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={data.btn_text} value="1" />
                            <Tab label={data.btn_img} value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ListNoimg />
                    </TabPanel>
                    <TabPanel value="2">
                        <ListWithImg />
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}

export default BtnTempInit
import React from 'react'
import { ManageInstanceContext } from '../../../../context/ManageInstanceContext'
import { Box, Typography, Divider, Button } from '@mui/material'
import { Stack } from '@mui/system'
import DialogAddInstance from './DialogAddInstance'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TranslateContext } from '../../../../context/TranslateContext'

const ManageInstanceInit = () => {
    const MANAGE_INSTANCE_CONTEXT = React.useContext(ManageInstanceContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{data.manage_ins_title}</Typography>
                <Button
                    onClick={() => MANAGE_INSTANCE_CONTEXT.setData({ ...MANAGE_INSTANCE_CONTEXT.data, dialog_add: true })}
                    size='small' variant='outlined' >{data.add_new_btn}</Button>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
            <DialogAddInstance />
        </Box>
    )
}

export default ManageInstanceInit
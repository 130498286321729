import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

export const BtnTempContext = React.createContext(null)

export const BtnTempProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_noimg: false,
        dialog_withimg: false
    })
    const history = useHistory()

    return (
        <BtnTempContext.Provider value={{ data, setData }}>
            {props.children}
        </BtnTempContext.Provider>
    )
}


import { Box, Grid, Typography, LinearProgress } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'

const DashScreen = () => {
    const { data } = React.useContext(TranslateContext)
    const [page, setPage] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")

    async function getData() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_dash",
            admin: true,
            post: false,
            token_user: token
        })
        setPage(res.data)
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])


    return (
        page ?
            <Box p={2} minHeight='80vh'>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box bgcolor={'#EEFFFC'} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight={'bold'} >{data.total_users}: {page.user || 0}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box bgcolor={'#EEF7FF'} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight={'bold'} >{data.total_plans}: {page.plan || 0}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box bgcolor={'#F6EEFF'} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight={'bold'} >{data.total_pings}: {page.ping || 0} </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box bgcolor={'#FFEEF3'} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight={'bold'} >{data.total_ordrs}: {page.order || 0} </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box> : <LinearProgress />
    )
}

export default DashScreen
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { ManageUserContext } from '../../../context/ManageUserContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Login, Visibility } from '@mui/icons-material'
import axios from 'axios'
import { TranslateContext } from '../../../context/TranslateContext';




const DataTableUser = () => {
    const MANAGE_USER_CONTEXT = React.useContext(ManageUserContext)
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")
    const [state, setState] = React.useState({})

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box p={2} width={'100%'}  >
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item>
                            <Typography variant='h5' >{data.al_users}</Typography>
                        </Grid>
                        <Grid item>
                            <GridToolbarExport />
                        </Grid>
                    </Grid>
                </Box>
            </GridToolbarContainer>
        );
    }

    async function getUser() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_all_user",
            post: false,
            admin: true,
            token_user: token
        })
        if (res.data.success) {
            MANAGE_USER_CONTEXT.setData({ ...MANAGE_USER_CONTEXT.data, user_data: res.data.data })
        }
    }

    function direcLogin(e) {
        setState({ ...state, loading: true })
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/direct_login`, {
            uid: e
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setState({ ...state, loading: false })
            localStorage.setItem(process.env.REACT_APP_TOKEN_ADMIN + "_user", res.data.token)
            window.open('/user')
        }).catch((err) => console.log(err))
    }


    React.useEffect(() => {
        if (!token) {
            return
        }
        getUser()
    }, [token])
    return (
        <div>

            {MANAGE_USER_CONTEXT.data.user_data ?
                <Box height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...MANAGE_USER_CONTEXT.data.user_data].reverse()}
                        columns={[
                            {
                                headerName: "Login", field: 'uid', flex: 1, renderCell: data =>
                                    state.loading ? (
                                        <CircularProgress size={25} />
                                    ) : (
                                        <IconButton
                                            onClick={() => direcLogin(data.row.uid)}
                                        >
                                            <Login />
                                        </IconButton>
                                    )
                            },
                            { headerName: data.name, field: 'name', flex: 1, },
                            { headerName: data.email, field: 'email', flex: 1, },
                            { headerName: data.mobile, field: 'mobile', flex: 1, },
                            {
                                headerName: data.plan, field: 'plan', flex: 1, renderCell: data => {
                                    return (
                                        data?.row.plan ? <Typography variant='body2'>{JSON.parse(data?.row.plan).name}</Typography> : <Typography variant='body2'>NA</Typography>
                                    )
                                }
                            },
                            {
                                headerName: data.action, field: 'id', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton
                                            onClick={() => MANAGE_USER_CONTEXT.setData({ ...MANAGE_USER_CONTEXT.data, dialog_user_edit: true, edit_user_data: data.row })}
                                            color='info' >
                                            <Visibility />
                                        </IconButton>
                                    )
                                }
                            },
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box> : ""
            }
        </div >
    )
}

export default DataTableUser
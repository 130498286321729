import { Button, Dialog, IconButton, Switch, TextField, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React from 'react'
import { ManagePlansContext } from '../../../context/ManagePlansContext'
import { GlobalContext } from '../../../context/GlobalContext'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { TranslateContext } from '../../../context/TranslateContext'

const DialogAdd = () => {
    const MANAGE_PLANS_CONTEXT = React.useContext(ManagePlansContext)
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [load, setLoad] = React.useState(false)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")

    async function getData() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/plan/get_all",
            post: false,
            admin: true,
            token_user: token
        })
        if (resp.data.success) {
            MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_data: resp.data.data, dialog_add: false })
        }
    }


    async function addPlan() {
        setLoad(true)
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/plan/add",
            post: true,
            admin: true,
            obj: {
                name: MANAGE_PLANS_CONTEXT.data.plan_name,
                message_limit: MANAGE_PLANS_CONTEXT.data.plan_msg_limit,
                cost: MANAGE_PLANS_CONTEXT.data.plan_cost,
                allow_bot: MANAGE_PLANS_CONTEXT.data.allow_bot,
                allow_group_export: MANAGE_PLANS_CONTEXT.data.allow_group_export,
                instance_limit: MANAGE_PLANS_CONTEXT.data.plan_instance_limit,
                description: MANAGE_PLANS_CONTEXT.data.plan_description,
            }
        })
        setLoad(false)
        if (resp.data.success) {
            getData()
        }
    }

    return (
        <Dialog open={MANAGE_PLANS_CONTEXT.data.dialog_add} fullScreen>
            <Box p={2}>
                <IconButton
                    onClick={() => MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, dialog_add: false })}>
                    <Close />
                </IconButton>
            </Box>

            <Container maxWidth='lg'>
                <Stack direction={'column'} spacing={2}>
                    <TextField onChange={(e) => {
                        MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_name: e.target.value })
                    }} label={data.plan_name} fullWidth size='small' />
                    <TextField type={'number'} onChange={(e) => {
                        MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_msg_limit: e.target.value })
                    }} label={data.msg_limit} fullWidth size='small' />
                    <TextField helperText="Type 0 for free plan" type={'number'} onChange={(e) => {
                        MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_cost: e.target.value })
                    }} label={data.cost} fullWidth size='small' />
                    <TextField type={'number'} onChange={(e) => {
                        MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_instance_limit: e.target.value })
                    }} label={data.home_plan_instance_limit} fullWidth size='small' />

                    <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                        <Typography variant='body2' >{data.allow_bot}</Typography>
                        <Switch
                            onChange={(e) => MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, allow_bot: MANAGE_PLANS_CONTEXT.data.allow_bot === 0 ? 1 : 0 })}
                            checked={MANAGE_PLANS_CONTEXT.data.allow_bot === 0 ? false : true} />
                    </Stack>

                    <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                        <Typography variant='body2' >{data.allow_grp_export}</Typography>
                        <Switch
                            onChange={(e) => MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, allow_group_export: MANAGE_PLANS_CONTEXT.data.allow_group_export === 0 ? 1 : 0 })}
                            checked={MANAGE_PLANS_CONTEXT.data.allow_group_export === 0 ? false : true} />
                    </Stack>


                    <TextField onChange={(e) => {
                        MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_description: e.target.value })
                    }} label={data.descriptio} fullWidth size='small' multiline rows={3} />

                    <LoadingButton
                        loading={load}
                        disabled={MANAGE_PLANS_CONTEXT.data.plan_name &&
                            MANAGE_PLANS_CONTEXT.data.plan_msg_limit &&
                            MANAGE_PLANS_CONTEXT.data.plan_cost &&
                            MANAGE_PLANS_CONTEXT.data.plan_instance_limit &&
                            MANAGE_PLANS_CONTEXT.data.plan_description ? false : true}
                        onClick={addPlan}
                        fullWidth variant='contained' size='small' >{data.add_new_btn}</LoadingButton>

                </Stack>
            </Container>

        </Dialog>
    )
}

export default DialogAdd
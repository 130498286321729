import React from 'react'
import { Box } from '@mui/material'
import OneF from './components/OneF'
import Header from './components/Header'
import TwoF from './components/TwoF'
import ThreeF from './components/ThreeF'
import PriceComp from './components/PriceComp'
import Footer from './components/Footer'
import GetWebSet from '../utils/GetWebSet'

const FrontEnd = () => {
    const [mode, setMode] = React.useState("")
    const { web_set } = GetWebSet()

    React.useEffect(() => {
        const mo = localStorage.getItem('theme')
        if (mo === 'light' || mo === 'dark') {
            if (mo === 'light') {
                setMode(color.light)
            } else {
                setMode(color.dark)
            }
        } else {
            setMode(color.dark)
        }
    }, [])

    var color = {
        dark: {
            bg: '#161d25',
            heading: "#fefffe",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#fefffe',
            btntext: "#161d25",
            shadowcolor: "white",
            twoboxcolor: "#252d34"
        },
        light: {
            bg: "#fefeff",
            heading: "#212b36",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#212b36',
            btntext: "#fefeff",
            shadowcolor: "black",
            twoboxcolor: "#f8fafa"
        }
    }

    return (
        mode && web_set &&
        <Box minHeight={'100vh'} bgcolor={mode.bg} >
            <Header mode={mode} web_set={web_set} />
            <OneF mode={mode} web_set={web_set} />
            <TwoF mode={mode} web_set={web_set} />
            <ThreeF mode={mode} web_set={web_set} />
            <PriceComp mode={mode} web_set={web_set} />
            <Footer mode={mode} web_set={web_set} />
        </Box>
    )
}

export default FrontEnd
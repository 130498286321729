import React from 'react'
import { Stack, Box, Divider, Typography, Button, IconButton, Tooltip, Dialog, Grid, CardMedia, ImageList, TextField } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { ArrowForward, Close, Download, Help, Sync } from '@mui/icons-material'
import { GlobalContext } from '../../context/GlobalContext'

const UserBackup = () => {
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        dialog: false
    })
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    async function updateData() {
        const data = JSON.parse(state.user_json)[2]?.data
        if (!data || data.length < 1) {
            return alert("Invalid JSON found or there are no users to restore.")
        }
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/restore_user",
            admin: true,
            post: true,
            obj: { data: data }
        })
        if (res.data.success) {
            setState({ ...state, user_json: "" })
        }
    }


    return (
        <Box p={2} >
            <Box>

                <Dialog fullWidth open={state.dialog_view} onClose={() => setState({ ...state, dialog_view: false })}>
                    <CardMedia src={state.view_url} height='100%' component={'img'} />
                </Dialog>

                <Dialog fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                    <Box mt={2}>
                        <ImageList
                            sx={{
                                gridAutoFlow: "column",
                                gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                gridAutoColumns: "minmax(160px, 1fr)",
                                msOverflowStyle: 'none',
                                overflowX: 'scroll',
                                '::-webkit-scrollbar': {
                                    width: '10px'
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#4F4F4F',
                                    borderRadius: 10
                                },
                                '::-webkit-scrollbar-thumb': {
                                    background: "#1B1B1B",
                                    borderRadius: 10
                                },
                                '::-webkit-scrollbar-thumb:hover': {
                                    background: 'green',
                                }
                            }}
                        >
                            <Stack mb={2} spacing={2} direction={'row'}>
                                <CardMedia onClick={() => {
                                    setState({ ...state, dialog_view: true, view_url: "/documentation/bb1.jpg" })
                                }} sx={{ cursor: 'pointer' }} src="/documentation/bb1.jpg" component='img' height={"100%"} />
                                <Stack flex={1} alignItems='center' justifyContent={'center'} >
                                    <ArrowForward />
                                </Stack>
                                <CardMedia onClick={() => {
                                    setState({ ...state, dialog_view: true, view_url: "/documentation/bb2.jpg" })
                                }} sx={{ cursor: 'pointer' }} src="/documentation/bb2.jpg" component='img' height={"100%"} />
                                <Stack flex={1} alignItems='center' justifyContent={'center'} >
                                    <ArrowForward />
                                </Stack>
                                <CardMedia onClick={() => {
                                    setState({ ...state, dialog_view: true, view_url: "/documentation/bb3.jpg" })
                                }} sx={{ cursor: 'pointer' }} src="/documentation/bb3.jpg" component='img' height={"100%"} />
                                <Stack flex={1} alignItems='center' justifyContent={'center'} >
                                    <ArrowForward />
                                </Stack>
                                <CardMedia onClick={() => {
                                    setState({ ...state, dialog_view: true, view_url: "/documentation/bb4.jpg" })
                                }} sx={{ cursor: 'pointer' }} src="/documentation/bb4.jpg" component='img' height={"100%"} />
                                <Stack flex={1} alignItems='center' justifyContent={'center'} >
                                    <ArrowForward />
                                </Stack>
                                <CardMedia onClick={() => {
                                    setState({ ...state, dialog_view: true, view_url: "/documentation/bb5.jpg" })
                                }} sx={{ cursor: 'pointer' }} src="/documentation/bb5.jpg" component='img' height={"100%"} />
                            </Stack>
                        </ImageList>
                    </Box>
                </Dialog>
                <Stack direction={'column'} >
                    <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                        <Stack direction={'column'}>
                            <Typography fontWeight={'bold'} >{data.user_backup_title}</Typography>
                            <Typography variant='caption' color='orange' >{data.user_backup_des}</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title={data.how_to_restore} >
                                <IconButton onClick={() => setState({ ...state, dialog: true })} color='warning' >
                                    <Help />
                                </IconButton>
                            </Tooltip>
                            {/* <Tooltip title={data.download_user_backup} >
                                <IconButton >
                                    <Download />
                                </IconButton>
                            </Tooltip> */}
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
            <Stack direction={'column'} spacing={2}>
                <TextField
                    value={state.user_json}
                    onChange={(e) => setState({ ...state, user_json: e.target.value })}
                    label={data.paste_json_content} fullWidth multiline rows={4} />
                <Button
                    disabled={!state.user_json}
                    onClick={updateData}
                    startIcon={<Sync />}
                    size='small' fullWidth variant='contained'>{data.import_users}</Button>
            </Stack>
        </Box>
    )
}

export default UserBackup
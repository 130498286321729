import { Delete, Visibility } from '@mui/icons-material'
import { Dialog, Grid, IconButton, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { BtnTempContext } from '../../../../context/BtnTempContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TranslateContext } from '../../../../context/TranslateContext'

const ListWithImg = () => {
    const BTNTEMP_CONTEXT = React.useContext(BtnTempContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const [state, setState] = React.useState({})
    const { data } = React.useContext(TranslateContext)

    async function getList() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/btntemp/get_btn_img",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            BTNTEMP_CONTEXT.setData({ ...BTNTEMP_CONTEXT.data, btnNoImgData: res.data.data })
        }
    }

    async function delBtn(e) {
        if (window.confirm("Are your sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/btntemp/del_btn",
                post: true,
                admin: false,
                obj: { id: e }
            })
            getList()
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getList()
    }, [token])

    return (
        <Box>
            <Grid container spacing={2}  >
                {BTNTEMP_CONTEXT.data?.btnNoImgData && [...BTNTEMP_CONTEXT.data?.btnNoImgData]?.reverse().map((i, key) => {
                    return (
                        <Grid key={key} xs={6} sm={4} lg={3} item>
                            <Box height={'100%'} boxShadow={4} borderRadius={4} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} p={1}>
                                <Stack alignItems={'center'} direction={'column'}>
                                    <Stack direction={'column'}>
                                        <Typography align='center' color={'white'}>{i.name}</Typography>
                                        <Typography align='center' fontSize={10} >{data.templet_id}: {i.id}</Typography>
                                    </Stack>
                                    <Stack direction={'row'}>
                                        <IconButton
                                            onClick={() => delBtn(i.id)}
                                            color='secondary' >
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ListWithImg
import React from 'react'
import { Box, Divider, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import GetUserByToken from '../../../utils/GetUserByToken'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'

const UpdateProfile = () => {
    const { userdata } = GetUserByToken()
    const [user, setUser] = React.useState({})
    const { data } = React.useContext(TranslateContext)
    React.useEffect(() => {
        if (!userdata) {
            return
        }
        setUser({ ...userdata })
    }, [userdata])
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const history = useHistory()

    async function updateUser() {
        await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/update",
            admin: false,
            post: true,
            obj: {
                name: user.name,
                email: user.email,
                new_password: user.new_password,
                id: user.id
            }
        })
    }

    return (
        <Box p={2} >
            <Stack direction={'row'} alignItems='center'>
                <Typography fontWeight="bold" >{data.update_profile}</Typography>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            {
                user.email ? (
                    <Stack direction={'column'} spacing={2}>
                        <TextField onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} label={data.name} size='small' fullWidth />
                        <TextField onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} label={data.email} size='small' fullWidth />
                        <TextField onChange={(e) => setUser({ ...user, new_password: e.target.value })} helperText={data.leavr_it_blank} label="Password" size='small' fullWidth />

                        <LoadingButton onClick={updateUser} fullWidth variant='contained' size='small' >{data.update_profile}</LoadingButton>
                    </Stack>
                ) : (
                    <LinearProgress />
                )
            }
        </Box>
    )
}

export default UpdateProfile
import React from 'react'
import GetButtonTempUser from '../../../../../utils/GetButtonTempUser'
import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, Slider, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { GlobalContext } from '../../../../../context/GlobalContext'
import GetInstanceUser from '../../../../../utils/GetInstanceUser'
import { CampaignContext } from '../../../../../context/CampaignContext'
import { TranslateContext } from '../../../../../context/TranslateContext'

const ButtonNumbers = () => {
    const { etemp } = GetButtonTempUser()
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        delay: [2, 10],
        unique_id: Date.now()
    })
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const CAMPAIGN_CONTEXT = React.useContext(CampaignContext)
    const { instance } = GetInstanceUser()

    async function handleSend() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/campaign/button",
            obj: state,
            admin: false,
            post: true
        })
        if (res.data.success) {
            CAMPAIGN_CONTEXT.setData({ ...CAMPAIGN_CONTEXT.data, campaign_done: true })
        }
    }

    function valuetext(value) {
        return `${value}sec`;
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>


                <TextField
                    value={state.number_data}
                    onChange={(e) => setState({ ...state, number_data: e.target.value.replace(/[^0-9\.,]/g, "") })}
                    label={data.pls_typ_nu} helperText={data.add_comma}
                    placeholder='918888888888, 15555555555' size='small' multiline rows={4} type='number'
                />

                <Box p={4} borderRadius={2} border={0.5} borderColor='gray' >
                    <Box mb={2} >
                        <Typography variant='caption' color={'gray'} >{data.randpm_delay_to_be} <a style={{ color: 'orange' }} >{state.delay[0]}</a> {data.to} <a style={{ color: 'orange' }} >{state.delay[1]}</a>{data.sec}</Typography>
                    </Box>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={state.delay}
                        onChange={(e, newVal) => setState({ ...state, delay: newVal })}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>

                {etemp ? (
                    <FormControl size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">{data.sel_btn_tmep}</InputLabel>
                        <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={}
                            label={data.sel_btn_tmep}
                            onChange={(e) => setState({ ...state, button_templet: e.target.value })}
                        >
                            {etemp.map((i, key) => {
                                return (
                                    <MenuItem key={key} value={i}>
                                        <Stack direction={'column'}>
                                            <Typography variant='caption'>{i.name}</Typography>
                                            <Typography variant='caption' color='gray'>{moment(i.createdAt).format("DD/MM/YYYY hh:mm A")}</Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : <LinearProgress />}

                {instance ? (
                    <FormControl size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">{data.sel_ins}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={""}
                            label={data.sel_ins}
                            onChange={(e) => setState({ ...state, instance: e.target.value })}
                        >
                            {instance.map((i, key) => {
                                return (
                                    <MenuItem
                                        key={key} disabled={i.status === 'ready to use' ? false : true}
                                        value={i}>{i.instance_number === "NULL" ? "NA" : i.instance_number}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : <LinearProgress />}



                <Button
                    onClick={handleSend}
                    disabled={state.number_data && state.button_templet && state.delay && state.instance ? false : true}
                    variant='contained' size='small'>{data.run_cam}</Button>
            </Stack>
        </div>
    )
}

export default ButtonNumbers
import React from 'react'
import { CamTempProvider } from '../../../context/CamTempContext'
import CamTInit from './CamTInit'

const CampTemp = () => {
    return (
        <CamTempProvider>
            <CamTInit />
        </CamTempProvider>
    )
}

export default CampTemp
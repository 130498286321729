import React from 'react'
import axios from 'axios'

const GetListTempUser = () => {
    const [ltemp, setState] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/msgtemp/list_temp`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setState(res.data.data)
        })
            .catch((err) => console.log(err))
    }, [token])
    return { ltemp }
}

export default GetListTempUser
import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { fontStyle, Stack } from '@mui/system'
import InstallationCost from './doc/InstallationCost'
import IntroComp from './doc/IntroComp'
import MySqlComp from './doc/MySqlComp'
import DeployComp from './doc/DeployComp'
import OtherStep from './doc/OtherStep'
import UpdateApp from './doc/UpdateApp'
import TransDoc from './doc/TransDoc'

const DocScreen = () => {
    return (
        <Container maxWidth='md'>
            <Box p={3}>
                <Typography sx={{ fontStyle: 'italic' }} align='center' >WhatsHam v2, Documentation by Codeyon IT Services</Typography>
            </Box>
            <Divider />
            <Box p={3}>
                <Stack direction={'column'}>
                    <Typography fontWeight={'bold'} variant='h6' align='center' >WhatsHam v2 - A Cloud base whatsapp SAAS System with Bot</Typography>
                    <Typography color='orange' align='center' >Note:- This script will not install on local server. Please install this on real domain as it will only install one time.</Typography>
                </Stack>
            </Box>
            <Divider />
            <Grid container>
                <Grid item xs={4} sm={4} lg={4}>
                    <Box p={3} bgcolor='action.hover'>
                        <Stack direction={'column'} >
                            <Typography variant='caption' >Created: 3 Feb 2023</Typography>
                            <Typography variant='caption' >By: hamidsaifi.com</Typography>
                            <Typography variant='caption' >Email: hello@hamidsaifi.com</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={8} sm={8} lg={8}>
                    <Box p={3} >
                        <Stack direction={'column'} >
                            <Typography variant='caption' >Thank you for purchasing WhatsHam. If you have any questions that are beyond the scope of this help file or any customer feature want to add on WhatsHam, please feel free to email at hello@hamidsaifi.com. Thanks so much!</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Divider />

            <InstallationCost />

            <IntroComp />

            <Divider />

            <MySqlComp />

            <Divider sx={{ mb: 3 }} />

            <DeployComp />

            <Divider sx={{ mb: 3 }} />

            <OtherStep />
            <Divider />

            <UpdateApp />

            <Divider sx={{ mb: 3 }} />
            <TransDoc />

            <Divider sx={{ mb: 3 }} />
            <InstallationCost />

            <Box pb={4}>
                <Stack direction={'column'} spacing={2}>
                    <Typography variant='body2' >Once again, thank you so much for purchasing ZapMais. As I said at the beginning, I'd be glad to help you if you have any questions relating to ZapMais. No guarantees, but I'll do my best to assist. If you have a more general question relating to the themes on ThemeForest, you might consider visiting the forums and asking your question in the "Item Discussion"</Typography>

                    <Typography fontStyle={'oblique'} >Hamid Saifi ❤️</Typography>
                </Stack>
            </Box>
        </Container>
    )
}

export default DocScreen
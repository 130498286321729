import React from 'react'
import { ManageInstanceProvider } from '../../../context/ManageInstanceContext'
import ManageInstanceInit from './components/ManageInstanceInit'

const ManageInstance = () => {

    return (
        <ManageInstanceProvider>
            <ManageInstanceInit />
        </ManageInstanceProvider>
    )
}

export default ManageInstance
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import { OpenInNew } from '@mui/icons-material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { Link } from 'react-router-dom'

const OneF = ({ mode, web_set }) => {
    const { data } = React.useContext(TranslateContext)
    return (
        <Container maxWidth='lg' >
            <Grid minHeight={'100vh'} justifyItems='center' alignItems={'center'} container spacing={2} justifyContent='space-between'>
                <Grid mt={10} xs={12} sm={6} lg={6} item>
                    <Stack direction={'column'} spacing={4}>
                        <Typography color={mode.heading} variant='h2' fontWeight={'bold'} >{data.home_hero_title} <a style={{ color: mode.orange }} >{web_set.app_name}</a></Typography>

                        <Typography color={mode.textgray} variant='body'>{data.home_hero_des}</Typography>

                        <Stack direction={'row'}>
                            <Button
                                component={Link}
                                to="/user/login"
                                variant='contained' style={{
                                    textTransform: 'none', backgroundColor: mode.btncolor, borderRadius: 10, padding: 10, paddingRight: 20, paddingLeft: 20,
                                    boxShadow: `0px 0px 45px -18px ${mode.shadowcolor}`, fontWeight: 'bold', color: mode.btntext
                                }} startIcon={<OpenInNew />} >
                                {data.home_get_started}
                            </Button>
                        </Stack>

                    </Stack>
                </Grid>
                <Grid xs={12} sm={6} lg={6} item>
                    <CardMedia src='/assets/undraw_Marketing_re_7f1g.png' component={'img'} height="100%" />
                </Grid>
            </Grid>
        </Container>
    )
}

export default OneF
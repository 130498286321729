import { Box, Button, Chip, Dialog, Divider, Grid, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { Close, AttachFile, Launch, Delete, ContentCopy } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'

const UploadMedia = () => {
    const [state, setState] = React.useState({
        dialog: false
    })
    const { data } = React.useContext(TranslateContext)
    const [uploadPer, setUploadPer] = React.useState(0)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    const [media, setMedia] = React.useState("")

    function uploadMedia(e) {
        e.preventDefault()
        setState({ ...state, loading: true })
        let fd = new FormData()
        fd.append('file', state.file)
        fd.append('mimetype', state.file.type)
        fd.append('type', state.file.name?.split('.').pop())
        fd.append('name', state.name)
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/uploadmedia/add`, fd, {
            headers: {
                Authorization: "Bearer " + token
            },
            onUploadProgress: e => {
                setUploadPer(parseInt(Math.round((e.loaded * 100) / e.total)))
            }
        }).then((res) => {
            if (!res.data.success) {
                alert(res.data.msg)
            } else {
                getAll()
                setState({ ...state, loading: false, media_url: window.location.protocol + '//' + window.location.host + "/user-media/" + res.data.filename, dialog: false })
            }
        })
    }

    async function getAll() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/uploadmedia/get",
            post: false,
            token_user: token,
            admin: false
        })
        setMedia(res.data.data)
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getAll()
    }, [token])

    async function delOne(e) {
        if (window.confirm("Are you sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/uploadmedia/del",
                post: true,
                admin: false,
                obj: {
                    id: e
                }
            })
        }
        getAll()
    }


    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Typography fontWeight={'bold'} >{data.upload_med}</Typography>
                <Button
                    onClick={() => {
                        setState({ ...state, dialog: true, media_url: "", file: "" })
                        setUploadPer(0)
                    }}
                    variant='outlined' size='small' >{data.add_new_btn}</Button>
            </Stack>
            <Box mt={2} >
                <Divider />
            </Box>

            <Dialog fullWidth open={state.dialog} >
                <Box p={2}>
                    <form key={1} encType='formdata/multi-part' onSubmit={uploadMedia} >
                        <Stack mb={2} direction={'column'} spacing={2}>
                            <TextField
                                onChange={(e) => setState({ ...state, name: e.target.value })}
                                label={data.add_a_name} fullWidth size='small' />
                            {
                                state.file || state.media_url ? (
                                    state.media_url ? (
                                        <Chip sx={{ minWidth: '100%' }} color='success' label={state.media_url} />
                                    ) :
                                        <Chip sx={{ minWidth: '100%' }} color='secondary' onDelete={() => {
                                            setState({ ...state, file: "" })
                                            setUploadPer(0)
                                        }} label={state.file.name} />
                                ) : (
                                    <Button fullWidth startIcon={<AttachFile />} size='small' component="label">
                                        <input
                                            accept="application/pdf, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/zip,
                                            application/mp3, image/*, video/*, audio/*"
                                            type="file"
                                            hidden
                                            onChange={(e) => {
                                                if (e.target.files[0].size > 15000000) {
                                                    return alert("Max accepted file is 15MB")
                                                }
                                                setState({ ...state, file: e.target.files[0] })
                                            }}
                                        />
                                        {data.add_med}
                                    </Button>
                                )
                            }
                            {uploadPer > 0 && <LinearProgress color={uploadPer === 100 ? 'success' : 'primary'} sx={{ mb: 2 }} variant="buffer" value={uploadPer} valueBuffer={uploadPer + 10} />}
                        </Stack>

                        {
                            state.file && !state.media_url && state.name &&
                            <LoadingButton
                                loading={state.loading}
                                fullWidth
                                color='secondary'
                                size='small'
                                variant='contained' type="submit" >
                                {data.upload_meda}
                            </LoadingButton>
                        }
                    </form>
                </Box>

                <Button size='small' variant='contained' fullWidth color='warning' onClick={() => setState({ ...state, dialog: false })}>
                    {data.close}
                </Button>
            </Dialog>


            <Grid mt={2} container spacing={2} >
                {media ?
                    media.map((i, key) => {
                        return (
                            <Grid key={key} xs={6} sm={4} lg={3} item>
                                <Box height={'100%'} boxShadow={4} borderRadius={4} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} p={1}>
                                    <Stack alignItems={'center'} direction={'column'}>
                                        <Typography color={'white'} variant='body2' >{i.name}</Typography>
                                        <Stack direction={'row'}>
                                            <IconButton
                                                size='small'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + "/user-media/" + i.filename).then(() => alert("Link copied to clipboard!"))
                                                }}
                                            >
                                                <ContentCopy />
                                            </IconButton>
                                            <IconButton
                                                size='small'
                                                onClick={() => window.open(`/user-media/${i.filename}`)} >
                                                <Launch />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => delOne(i.id)}
                                                size='small'
                                                color='secondary' >
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                        <Typography variant='caption' color={'black'}  >{i.type}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        )
                    }) : <LinearProgress />}
            </Grid>

        </Box >
    )
}

export default UploadMedia
import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { ManagePlansProvider } from '../../context/ManagePlansContext'
import ManagePlansInit from './components/ManagePlansInit'

const ManagePlans = () => {
    return (
        <ManagePlansProvider>
            <ManagePlansInit />
        </ManagePlansProvider>
    )
}

export default ManagePlans
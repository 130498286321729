import React from 'react'
import { useHistory } from 'react-router-dom'

export const ManageBotContext = React.createContext(null)

export const ManageBotProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog: false,
        with_media: false
    })
    const history = useHistory()

    return (
        <ManageBotContext.Provider value={{ data, setData }}>
            {props.children}
        </ManageBotContext.Provider>
    )
}


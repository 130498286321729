import React from 'react'
import { useHistory } from 'react-router-dom'

export const ManagePlansContext = React.createContext(null)

export const ManagePlansProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_add: false,
        allow_bot: 0,
        allow_group_export: 0
    })
    const history = useHistory()

    return (
        <ManagePlansContext.Provider value={{ data, setData }}>
            {props.children}
        </ManagePlansContext.Provider>
    )
}


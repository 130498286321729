import React from 'react'
import { useHistory } from 'react-router-dom'

export const ManageUserContext = React.createContext(null)

export const ManageUserProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_user_edit: false,
        edit_user_data: {}
    })
    const history = useHistory()

    return (
        <ManageUserContext.Provider value={{ data, setData }}>
            {props.children}
        </ManageUserContext.Provider>
    )
}


import { Box } from '@mui/system'
import React from 'react'
import { ManageUserProvider } from '../../context/ManageUserContext'
import { GlobalContext } from '../../context/GlobalContext'
import { Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import ManageUserInit from './components/ManageUserInit'
import { TranslateContext } from '../../context/TranslateContext'
import { Close } from '@mui/icons-material'

const ManageUser = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({})
    return (
        <ManageUserProvider>
            <Box p={2}>
                <Stack direction={'row'} spacing={2} justifyContent='space-between' alignItems={'center'}>
                    <Typography fontWeight={'bold'} >{data.manage_users}</Typography>
                </Stack>
                <Box mt={2} mb={2}>
                    <Divider />
                </Box>
                <ManageUserInit />
            </Box>
        </ManageUserProvider>
    )
}

export default ManageUser
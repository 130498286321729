import { Close, AttachFile } from '@mui/icons-material'
import { Container, Dialog, IconButton, Stack, Button, LinearProgress, Switch, TextField, Typography, Chip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import React from 'react'
import { CamTempContext } from '../../../../context/CamTempContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import { TranslateContext } from '../../../../context/TranslateContext'

const PasteDialog = ({ close }) => {
    const CAMTEMP_CONTACT = React.useContext(CamTempContext)
    const { data } = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const [state, setState] = React.useState({})
    const [uploadPer, setUploadPer] = React.useState(0)


    function uploadMedia(e) {
        e.preventDefault()
        setState({ ...state, loading: true })
        let fd = new FormData()
        fd.append('file', state.file)
        fd.append('filetype', state.filetype)
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/instance/add_media`, fd, {
            headers: {
                Authorization: "Bearer " + token
            },
            onUploadProgress: e => {
                setUploadPer(parseInt(Math.round((e.loaded * 100) / e.total)))
            }
        }).then((res) => {
            if (!res.data.success) {
                alert(res.data.msg)
            } else {
                setState({ ...state, loading: false })
                CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, media_url: res.data.filename, filetype: res.data.filetype })
            }
        })
    }

    async function getPastedOne() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            token_user: token,
            post: false,
            admin: false,
            path: "/api/msgtemp/get_paste"
        })
        CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT, pasted_string: resp.data.data, dialog: false, number_data: "", media_url: "", sending_text: "", with_media: "", name: "" })
    }

    async function addCam() {
        setState({ ...state, loading: true })
        const resp = await GLOBAL_CONTEXT.hitAxios({
            post: true,
            admin: false,
            path: "/api/msgtemp/add",
            obj: {
                pastedString: CAMTEMP_CONTACT.data.number_data,
                media: CAMTEMP_CONTACT.data.with_media ? CAMTEMP_CONTACT.data.media_url : null,
                excelJson: null,
                message: CAMTEMP_CONTACT.data.sending_text,
                name: CAMTEMP_CONTACT.data.name
            }
        })
        if (resp.data.success) {
            getPastedOne()
            setState({ ...state, loading: false })
        }
        setState({ ...state, loading: false })
    }


    return (
        <Dialog open={CAMTEMP_CONTACT.data.dialog} fullScreen >
            <Box mb={2} p={2}>
                <IconButton onClick={() => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, dialog: false })} >
                    <Close />
                </IconButton>
            </Box>
            <Container maxWidth='lg'>
                <Stack direction={'column'} spacing={2}>

                    <TextField
                        value={CAMTEMP_CONTACT.data.name}
                        onChange={(e) => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, name: e.target.value })}
                        label={data.temp_name} size='small'
                    />


                    <TextField
                        value={CAMTEMP_CONTACT.data.number_data}
                        onChange={(e) => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, number_data: e.target.value.replace(/[^0-9\.,]/g, "") })}
                        label={data.pls_typ_nu} helperText={data.add_comma}
                        placeholder='918888888888, 15555555555' size='small' multiline rows={4} type='number'
                    />

                    <TextField
                        value={CAMTEMP_CONTACT.data.sending_text}
                        onChange={(e) => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, sending_text: e.target.value })}
                        label={data.write_your_msg} size='small' multiline rows={4} />

                    <Box borderRadius={2} border={0.5} p={2} mt={2} mb={2}>
                        <Stack justifyContent={'space-between'} direction={'row'} alignItems='center' >
                            <Typography variant='caption'>{data.with_me}</Typography>
                            <Switch
                                onChange={(e) => CAMTEMP_CONTACT.setData({ ...CAMTEMP_CONTACT.data, with_media: !CAMTEMP_CONTACT.data.with_media })}
                                checked={CAMTEMP_CONTACT.data.with_media} />
                        </Stack>

                        {CAMTEMP_CONTACT.data.with_media ?
                            <Box mt={2} >
                                <form key={1} encType='formdata/multi-part' onSubmit={uploadMedia} >
                                    {
                                        <Stack mb={2} direction={'row'}>

                                            {
                                                state.file || CAMTEMP_CONTACT.data.media_url ? (
                                                    CAMTEMP_CONTACT.data.media_url ? (
                                                        <Chip color='success' label={CAMTEMP_CONTACT.data.media_url} />
                                                    ) :
                                                        <Chip color='secondary' onDelete={() => {
                                                            setState({ ...state, file: "" })
                                                            setUploadPer(0)
                                                        }} label={state.file.name} />
                                                ) : (
                                                    <Button startIcon={<AttachFile />} size='small' component="label">
                                                        <input
                                                            accept="application/pdf, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/zip,
                                                        application/mp3, image/*, video/*, audio/*"
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {
                                                                if (e.target.files[0].size > 15000000) {
                                                                    return alert("Max accepted file is 15MB")
                                                                }
                                                                setState({ ...state, file: e.target.files[0], filetype: e.target.files[0].name?.split('.').pop() })
                                                            }}
                                                        />
                                                        {data.add_med}
                                                    </Button>
                                                )
                                            }
                                        </Stack>
                                    }

                                    {uploadPer > 0 && <LinearProgress color={uploadPer === 100 ? 'success' : 'primary'} sx={{ mb: 2 }} variant="buffer" value={uploadPer} valueBuffer={uploadPer + 10} />}

                                    {
                                        state.file && !CAMTEMP_CONTACT.data.media_url &&
                                        <LoadingButton
                                            loading={state.loading}
                                            fullWidth
                                            color='secondary'
                                            size='small'
                                            variant='contained' type="submit" >
                                            {data.upload_meda}
                                        </LoadingButton>
                                    }
                                </form>
                            </Box>
                            : null}
                    </Box>

                    {CAMTEMP_CONTACT.data.with_media ?
                        <LoadingButton
                            loading={state.loading}
                            onClick={addCam}
                            disabled={CAMTEMP_CONTACT.data.number_data && CAMTEMP_CONTACT.data.media_url && CAMTEMP_CONTACT.data.sending_text && CAMTEMP_CONTACT.data.name ? false : true}
                            variant='contained' fullWidth size='small'>
                            {data.add_ths_temp}
                        </LoadingButton> :
                        <LoadingButton
                            onClick={addCam}
                            disabled={CAMTEMP_CONTACT.data.number_data && CAMTEMP_CONTACT.data.sending_text && CAMTEMP_CONTACT.data.name ? false : true}
                            variant='contained' loading={state.loading} fullWidth size='small'>
                            {data.add_ths_temp}
                        </LoadingButton>}
                </Stack>
            </Container>
        </Dialog>
    )
}

export default PasteDialog
import { Close, QrCodeScanner, Delete } from '@mui/icons-material'
import { Typography, Container, Dialog, Grid, IconButton, Stack, LinearProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import axios from 'axios'
import React from 'react'
import { GlobalContext } from '../../../../context/GlobalContext'
import { ManageInstanceContext } from '../../../../context/ManageInstanceContext'
import qrcode from 'qrcode'
import moment from 'moment'
import { TranslateContext } from '../../../../context/TranslateContext'


const DialogAddInstance = () => {
    const MANAGE_INSTANCE_CONTEXT = React.useContext(ManageInstanceContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({})
    const [qr, setQR] = React.useState("")
    const client_id = Date.now()
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const { data } = React.useContext(TranslateContext)

    function qrtoBase64(qr) {
        // console.log({ rec_in_qr: qr })
        qrcode.toDataURL(qr, (err, src) => {
            var base64Data = src?.replace(/^data:image\/png;base64,/, "")
            setQR(base64Data)
        });
    }

    function getQRFromDb(unique_id) {
        const timer = setInterval(async () => {

            axios.post(`${process.env.REACT_APP_BASE_URL}/api/instance/get_qr`, {
                unique_id: unique_id
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then((res) => {
                if (res.data.data.qr) {
                    setState({ ...state, loading: false })
                    qrtoBase64(res.data.data.qr)
                }
                if (res.data.data.status === 'ready to use') {
                    alert("Login Success")
                    getInstance()
                    // history.push("/user?page=2")
                    clearInterval(timer)
                }
            }).catch(((err) => console.log(err)))

        }, 3000)
    }

    async function getInstance() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            post: false,
            token_user: token,
            admin: false,
            path: "/api/instance/get_instance_uid"
        })

        if (res.data.success) {
            MANAGE_INSTANCE_CONTEXT.setData({ ...MANAGE_INSTANCE_CONTEXT.data, instance_list: res.data.data, dialog_add: false })
            setQR("")
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getInstance()
    }, [token])


    async function genQR() {
        setState({ ...state, loading: true })
        const res = await GLOBAL_CONTEXT.hitAxios({
            post: true,
            path: "/api/instance/add_new",
            admin: false,
            obj: {
                client_id: client_id
            }
        })
        if (res.data.success) {
            getQRFromDb(client_id)
            qrtoBase64(res.data.qr)
        }
    }

    async function delIns(e) {
        if (window.confirm("Are your sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                post: true,
                admin: false,
                path: "/api/instance/del_instance",
                obj: { client_id: e }
            })
            getInstance()
        }
    }

    return (
        <>
            <Dialog open={MANAGE_INSTANCE_CONTEXT.data.dialog_add} fullScreen >
                <Box p={2} >
                    <IconButton onClick={() => MANAGE_INSTANCE_CONTEXT.setData({ ...MANAGE_INSTANCE_CONTEXT.data, dialog_add: false })} >
                        <Close />
                    </IconButton>
                </Box>
                <Container maxWidth='lg'>

                    <Grid spacing={2} justifyContent={'center'} direction='column' alignItems={'center'} container minHeight={'80vh'}>
                        <Grid item>
                            {qr && <img src={`data:image/png;base64,${qr}`} />}
                            <LinearProgress />
                        </Grid>
                        {!qr &&
                            <Grid item>
                                <LoadingButton
                                    loading={state.loading}
                                    endIcon={<QrCodeScanner />}
                                    color='warning'
                                    sx={{ borderRadius: 2 }}
                                    size='large'
                                    variant='contained'
                                    onClick={genQR} >{data.manage_ins_gen}</LoadingButton>
                            </Grid>}
                    </Grid>
                </Container>

            </Dialog>
            <Stack direction={'column'} spacing={2}>
                {
                    MANAGE_INSTANCE_CONTEXT.data.instance_list &&
                    [...MANAGE_INSTANCE_CONTEXT.data?.instance_list].reverse().map((i, key) => {
                        return (
                            <Box boxShadow={5} borderRadius={2} p={2} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} >
                                <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                    <Stack direction={'column'}>
                                        <Typography color='white' fontWeight={'bold'} >{i.instance_number === "NULL" ? "NA" : i.instance_number}</Typography>
                                        <Typography variant='caption' color='gray' >{moment(i.created_at).format("HH:mm A - DD/MM/YY")}</Typography>
                                        <Typography textTransform={'capitalize'} variant='caption' color='#70F770' >{i.status === "ready to use" ? data.manage_ins_ready : i.status}</Typography>
                                    </Stack>
                                    <Stack direction={'row'}>
                                        <IconButton
                                            onClick={() => delIns(i.client_id)}
                                            color='error' >
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    })}
            </Stack >
        </>
    )
}

export default DialogAddInstance
import React from 'react'
import { CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { TranslateContext } from '../../context/TranslateContext'

const TwoF = ({ mode, web_set }) => {
    const { data } = React.useContext(TranslateContext)
    return (
        <Container sx={{ mt: 10, pb: 10 }} maxWidth='lg' >
            <Grid minHeight={'100vh'} justifyItems='center' alignItems={'center'} container spacing={2} justifyContent='space-between'>
                <Grid xs={12} sm={12} lg={12} item>
                    <Box p={5} bgcolor={mode.twoboxcolor} borderRadius={4}>
                        <Grid container alignItems={'center'} justifyContent="center" direction={'column'} spacing={3}>
                            <Grid xs={12} sm={4} lg={4} item>
                                <CardMedia src="/assets/undraw_heatmap_uyye.png" component='img' />
                            </Grid>
                            <Grid xs={12} sm={4} lg={4} item>
                                <Typography align='center' variant='h4' fontWeight={'bold'}>{data.home_why_only} <a style={{ color: mode.orange }} >{web_set.app_name}</a>?</Typography>
                            </Grid>
                            <Grid xs={12} sm={4} lg={4} item>
                                <Typography sx={{ width: 600, textAlign: 'center' }} align='center' color={mode.textgray} variant='body'>{data.home_why_onlye_des}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default TwoF
import React from 'react'
import { ManageBotProvider } from '../../../context/ManageBotContext'
import ManageBotInit from './ManageBotInit'

const ManageBot = () => {
    return (
        <ManageBotProvider>
            <ManageBotInit />
        </ManageBotProvider>
    )
}

export default ManageBot
import axios from 'axios'
import React from 'react'

const GetUserMedia = () => {
    const [media, setMedia] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    React.useEffect(() => {
        if (!token) {
            return
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/uploadmedia/get`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => setMedia(res.data.data))
            .catch((err) => console.log(err))
    }, [token])
    return { media }
}

export default GetUserMedia
import { Box, Button, Chip, Divider, FormControl, InputLabel, Tab, LinearProgress, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import GetInstanceUser from '../../../../utils/GetInstanceUser'
import moment from 'moment'
import { ManageBotContext } from '../../../../context/ManageBotContext'
import GetUserMedia from '../../../../utils/GetUserMedia'
import { Add } from '@mui/icons-material'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TabContext, TabList, TabPanel, LoadingButton } from '@mui/lab'
import GetButtonTempUser from '../../../../utils/GetButtonTempUser'
import GetListTempUser from '../../../../utils/GetListTempUser'
import { TranslateContext } from '../../../../context/TranslateContext'

const TextBot = () => {
    const { instance } = GetInstanceUser()
    const MANAGEBOT_CONTEXT = React.useContext(ManageBotContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const { media } = GetUserMedia()
    const { etemp } = GetButtonTempUser()
    const { ltemp } = GetListTempUser()

    const [bot, setBot] = React.useState([])
    const [state, setState] = React.useState({
        with_media: false,
        bot_type: 'text'
    })
    const [ins, setIns] = React.useState(null)
    const [load, setLoad] = React.useState(false)

    const [value, setValue] = React.useState('text');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function handleSend() {
        setLoad(true)
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/bot/add_text",
            obj: {
                client_id: ins,
                bots: bot,
                unique_id: Date.now(),
                bot_type: state.bot_type
            },
            admin: false,
            post: true
        })
        console.log(resp.data)
        setLoad(false)
        if (resp.data.success) {
            MANAGEBOT_CONTEXT.setData({ ...MANAGEBOT_CONTEXT.data, dialog: false })
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='body2' >{data.add_rep_with_text_n_img}</Typography>

                <TextField
                    helperText={data.incoming_msg}
                    value={state.incoming}
                    onChange={(e) => setState({ ...state, incoming: e.target.value })}
                    label={data.ping} multiline rows={2} size='small' />



                <Box bgcolor={'action.hover'} borderRadius={2} minHeight={200} >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList variant='scrollable' onChange={handleChange} aria-label="a">
                                <Tab
                                    onClick={() => setState({ ...state, bot_type: 'text' })}
                                    label={data.rep_with_text} value="text" />
                                <Tab
                                    onClick={() => setState({ ...state, bot_type: 'button' })}
                                    label={data.rep_with_btn} value="button" />
                                <Tab
                                    onClick={() => setState({ ...state, bot_type: 'list', with_media: false, media: "" })}
                                    label={data.rep_with_list} value="list" />
                            </TabList>
                        </Box>
                        <TabPanel value="text">
                            <Stack >
                                <TextField
                                    helperText={data.set_rep_for_incoming}
                                    value={state.outgoing}
                                    onChange={(e) => setState({ ...state, outgoing: e.target.value })}
                                    label="Reply" multiline rows={2} size='small' />
                            </Stack>
                        </TabPanel>

                        <TabPanel value="button">
                            {etemp ?
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="btn-simple-select-label">{data.sel_btn_tmep}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="btn-simple-select-label"
                                        id="btn-simple-select"
                                        // value={Select etemp}
                                        label={data.sel_btn_tmep}
                                        onChange={(e) => setState({ ...state, btntemp: e.target.value })}
                                    >
                                        {etemp.map((i, key) => {
                                            return (
                                                <MenuItem
                                                    key={key} value={i}>
                                                    <Stack direction={'column'}>
                                                        <Typography variant='body2'>{i.name}</Typography>
                                                        <Typography variant='caption' color='gray'>{moment(i.created_at).format("DD/MM/YY - hh:mm A")}</Typography>
                                                    </Stack>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl> : <LinearProgress />}
                        </TabPanel>

                        <TabPanel value="list">
                            {ltemp ?
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="btn-simple-select-label">{data.sel_list_temp}</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="btn-simple-select-label"
                                        id="btn-simple-select"
                                        // value={Select ltemp}
                                        label={data.sel_list_temp}
                                        onChange={(e) => setState({ ...state, listtemp: e.target.value })}
                                    >
                                        {ltemp.map((i, key) => {
                                            return (
                                                <MenuItem key={key} value={i}>
                                                    <Stack direction={'column'}>
                                                        <Typography variant='body2'>{i.buttonText}</Typography>
                                                        <Typography variant='caption' color='gray'>{moment(i.created_at).format("DD/MM/YY - hh:mm A")}</Typography>
                                                    </Stack>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl> : <LinearProgress />}
                        </TabPanel>
                    </TabContext>
                </Box>


                {state.bot_type === 'text' &&
                    <Box p={3} borderRadius={2} border={0.5}>
                        <Stack b={2} justifyContent={'space-between'} direction={'row'} spacing={2} alignItems='center'>
                            <Typography variant='body2'>{data.with_me}</Typography>
                            <Switch checked={state.with_media}
                                onChange={(e) => setState({ ...state, with_media: e.target.checked })} />
                        </Stack>
                        {state.with_media ?
                            media ? <FormControl sx={{ marginTop: 2 }} size='small' fullWidth>
                                <InputLabel id="-simple-select-label">{data.sel_img}</InputLabel>
                                <Select
                                    size='small'
                                    labelId="-simple-select-label"
                                    id="-simple-select"
                                    value={state.media}
                                    label={data.sel_img}
                                    onChange={(e) => setState({ ...state, media: e.target.value })}
                                >
                                    {media?.map((i, key) => {
                                        return (
                                            <MenuItem
                                                key={key} value={i.filename}>{i.name}.<a style={{ color: 'gray', fontSize: 12 }} >{i.type}</a></MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl> : <LinearProgress /> : null
                        }
                    </Box>}


                {state.bot_type === 'text' &&
                    <Button
                        disabled={state.with_media ? state.incoming && state.outgoing && state.media ? false : true : state.incoming && state.outgoing ? false : true}
                        onClick={() => {
                            setBot([...bot, {
                                id: Date.now(),
                                incoming: state.incoming,
                                outgoing: state.outgoing,
                                with_media: state.with_media,
                                media: state.media,
                                bot_type: "text"
                            }])
                            setState({ ...state, incoming: "", outgoing: "", with_media: false, media: "" })
                        }}
                        startIcon={<Add />}
                        variant='outlined' size='small' fullWidth >{data.add_text}</Button>}

                {state.bot_type === 'button' &&
                    <Button
                        disabled={state.incoming && state.btntemp ? false : true}
                        onClick={() => {
                            setBot([...bot, {
                                id: Date.now(),
                                incoming: state.incoming,
                                outgoing: state.btntemp,
                                bot_type: "button"
                            }])
                            setState({ ...state, incoming: "", outgoing: "", with_media: false, media: "" })
                        }}
                        startIcon={<Add />}
                        variant='outlined' size='small' fullWidth >{data.add_btn}</Button>}

                {state.bot_type === 'list' &&
                    <Button
                        disabled={state.incoming && state.listtemp ? false : true}
                        onClick={() => {
                            setBot([...bot, {
                                id: Date.now(),
                                incoming: state.incoming,
                                outgoing: state.listtemp,
                                bot_type: "list"
                            }])
                            setState({ ...state, incoming: "", outgoing: "", with_media: false, media: "" })
                        }}
                        startIcon={<Add />}
                        variant='outlined' size='small' fullWidth >{data.add_lst}</Button>}

                <Divider />

                <Stack direction={'row'} spacing={2} >
                    {bot.map((i, key) => {
                        return (
                            <>
                                <Tooltip title={i.incoming} >
                                    <Chip
                                        onDelete={() => {
                                            var data = bot.filter(ii => ii.id !== i.id)
                                            setBot(data)
                                        }}
                                        label={i.incoming.slice(0, 6) + "..."} />
                                </Tooltip>
                            </>
                        )
                    })}
                </Stack>

                {instance ?
                    <FormControl fullWidth>
                        <InputLabel size='small' id="demo-simple-select-label">{data.sel_ins}</InputLabel>
                        <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={Select Instance}
                            label={data.sel_ins}
                            onChange={(e) => setIns(e.target.value)}
                        >
                            {instance.map((i, key) => {
                                return (
                                    <MenuItem
                                        disabled={i.status === 'ready to use' ? false : true}
                                        key={key} value={i.client_id}>
                                        <Stack direction={'column'}>
                                            <Typography variant='body2'>{i.instance_number}</Typography>
                                            <Typography variant='caption' color='gray'>{moment(i.created_at).format("DD/MM/YY - hh:mm A")}</Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> : <LinearProgress />}

                <LoadingButton
                    loading={load}
                    onClick={handleSend}
                    disabled={bot.length > 0 && ins ? false : true}
                    variant='contained' fullWidth >{data.run_bo}</LoadingButton>


            </Stack>
        </div>
    )
}

export default TextBot
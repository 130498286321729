import React from 'react'
import PublicRoute from './routing/PublicRoute'
import AdminRoute from './routing/AdminRoute'
import LoginAdmin from './admin/LoginAdmin'
import AdminDashboard from './admin/AdminDashboard'
import LoginUser from './user/LoginUser';
import UserRoute from './routing/UserRoute'
import UserDashboard from './user/UserDashboard';
import { Route, Switch } from 'react-router-dom'
import { GlobalContext } from './context/GlobalContext'
import { Box, Snackbar, LinearProgress } from '@mui/material'
import FrontEnd from './frontend/FrontEnd'
import axios from 'axios'
import { TranslateContext } from './context/TranslateContext'
import CheckOutPage from './frontend/checkout/CheckOutPage'
import InfoPage from './frontend/InfoPage'
import Error404 from './Error404'
import RecoveryAdmin from './recovery/RecoveryAdmin'
import RecoveryUser from './recovery/RecoveryUser'
import InstallAppScreen from './InstallAppScreen'
import { Helmet } from "react-helmet";
import DocScreen from './doc/DocScreen'

export default function MainPage() {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [lang, setLang] = React.useState("")
    const LANGUAGE = React.useContext(TranslateContext)
    const [web, setWeb] = React.useState("")

    async function getWeb() {
        const res1 = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/check_install`)
        if (res1.data.success) {
            const we = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/get`)
            setWeb(we.data.data)
        }
    }


    React.useEffect(() => {
        getWeb()
        const code = localStorage.getItem('language')
        // const localcode = local.slice(0, local.indexOf('.'))
        if (code) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/translate/get?code=${code}`)
                .then((res) => {
                    if (res.data.notfound) {
                        localStorage.removeItem('language')
                        alert("Maybe translate file issue.")
                        window.location.reload()
                        return
                    }
                    LANGUAGE.setData({ ...res.data.data })
                })
                .catch((err) => console.log(err))
        } else {
            localStorage.setItem('language', 'en')
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/translate/get?code=en`)
                .then((res) => {
                    LANGUAGE.setData({ ...res.data.data })
                })
                .catch((err) => console.log(err))
        }

    }, [])

    return (
        <Box sx={{ position: 'relative' }} color='text.primary' minHeight={'100vh'} bgcolor='background.default'>
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, display: GLOBAL_CONTEXT.data.loading ? 'block' : 'none' }} >
                <LinearProgress />
            </div>
            <Snackbar autoHideDuration={3000} open={GLOBAL_CONTEXT.data.snack} message={GLOBAL_CONTEXT.data.snack_msg} />
            <div style={{
                opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
                pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial"
            }}>

                {web ?
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{web.app_name}</title>
                        <link rel="canonical" href={window.location.hostname} />
                        <link rel="icon" href={`/images/${web.logo}`} />
                    </Helmet>
                    : null}

                {LANGUAGE.data ? <Switch>
                    <AdminRoute exact path="/admin" component={AdminDashboard} />
                    <PublicRoute exact path="/install" component={InstallAppScreen} />
                    <PublicRoute exact path="/v2/doc" component={DocScreen} />
                    <PublicRoute exact path="/admin/login" component={LoginAdmin} />
                    <PublicRoute exact path="/user/login" component={LoginUser} />
                    <PublicRoute exact path="/recovery-user/:id" component={RecoveryUser} />
                    <PublicRoute exact path="/recovery-admin/:id" component={RecoveryAdmin} />
                    <PublicRoute exact path="/" component={FrontEnd} />
                    <PublicRoute exact path="/page/:slug" component={InfoPage} />
                    <UserRoute exact path="/user" component={UserDashboard} />
                    <UserRoute exact path="/user/checkout" component={CheckOutPage} />
                    <Route path='*' component={Error404} />
                </Switch> :
                    <LinearProgress />}
            </div>
        </Box>

    )
}
import React from 'react'
import { useHistory } from 'react-router-dom'

export const CamTempContext = React.createContext(null)

export const CamTempProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog: false,
        dialog_excel: false,
        with_media: false,
    })
    const history = useHistory()

    return (
        <CamTempContext.Provider value={{ data, setData }}>
            {props.children}
        </CamTempContext.Provider>
    )
}


import React from 'react'
import AppConfig from './app-config.js/AppConfig'
import ManagePlans from './manage-plans/ManagePlans'
import ManageUser from './manage-users/ManageUser'
import DashScreen from './pages/DashScreen'
import ManagePayGateway from './payment-gateways/ManagePayGateway'
import PingFromUser from './ping-user/PingFromUser'
import SMTPConfig from './smtp-config/SMTPConfig'
import TransLog from './trans-log/TransLog'
import ProfileSet from './profile/ProfileSet'
import ManagePage from './manage-page/ManagePage'
import UserBackup from './user-backup/UserBackup'
import ManageModules from './maage-modules/ManageModules'

const DashboardPage = (props) => {
    return (
        <div>
            {props.page === 0 && <DashScreen />}
            {props.page === 1 && <ManageUser />}
            {props.page === 2 && <ManagePlans />}
            {props.page === 3 && <ManagePayGateway />}
            {props.page === 4 && <SMTPConfig />}
            {props.page === 5 && <PingFromUser />}
            {props.page === 6 && <AppConfig />}
            {props.page === 7 && <TransLog />}
            {props.page === 8 && <ManagePage />}
            {props.page === 9 && <ProfileSet />}
            {props.page === 10 && <UserBackup />}
            {props.page === 11 && <ManageModules />}
        </div>
    )
}

export default DashboardPage
import React from 'react'
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import GetWebSet from '../utils/GetWebSet'
import { GlobalContext } from '../context/GlobalContext'
import { TranslateContext } from '../context/TranslateContext'
import { Container } from '@mui/system'
import Header from './components/Header'
import { Helmet } from "react-helmet";
import Footer from './components/Footer'

const InfoPage = (props) => {
    const [mode, setMode] = React.useState("")
    const { web_set } = GetWebSet()
    const slug = props.match.params.slug
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [page, setPage] = React.useState("")
    const { data } = React.useContext(TranslateContext)

    async function getPage() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/page/get_by_slug",
            post: true,
            admin: true,
            obj: { slug: slug }
        })
        if (resp.data.success) {
            setPage(resp.data.data)
        } else {
            setPage("nopage")
        }
    }

    React.useEffect(() => {
        if (!slug) {
            return
        }
        getPage()
    }, [slug])

    React.useEffect(() => {
        const mo = localStorage.getItem('theme')
        if (mo === 'light' || mo === 'dark') {
            if (mo === 'light') {
                setMode(color.light)
            } else {
                setMode(color.dark)
            }
        } else {
            setMode(color.dark)
        }
    }, [])

    var color = {
        dark: {
            bg: '#161d25',
            heading: "#fefffe",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#fefffe',
            btntext: "#161d25",
            shadowcolor: "white",
            twoboxcolor: "#252d34"
        },
        light: {
            bg: "#fefeff",
            heading: "#212b36",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#212b36',
            btntext: "#fefeff",
            shadowcolor: "black",
            twoboxcolor: "#f8fafa"
        }
    }

    return (
        <Box bgcolor={mode.bg} >
            {page ?
                page === 'nopage' ?
                    <Grid container minHeight={'100vh'} alignItems='center' justifyContent={'center'}>
                        <Grid item>
                            <Typography>{data.no_page_found}</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Container maxWidth='lg'>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title >{page.title}</title>
                            <meta name="description" content={page.meta} />
                            <link rel="canonical" href={window.location.hostname} />
                        </Helmet>
                        <Header mode={mode} web_set={web_set} />
                        <Box pt={15} >
                            <Box p={2}>
                                <Typography sx={{ mb: 2 }} fontWeight={'bold'} align='center' variant='h3' >{page.title}</Typography>
                                <Divider variant='middle' />
                            </Box>
                            <Typography
                                sx={{ display: "inline-block" }}
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: page.content
                                }}
                            ></Typography>
                        </Box>
                    </Container>
                : <LinearProgress />}

            <Footer />
        </Box>
    )
}

export default InfoPage
import { Close } from '@mui/icons-material'
import { Dialog, IconButton, Tab } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { ManageBotContext } from '../../../../context/ManageBotContext'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TextBot from './TextBot'

const DialogMain = () => {
    const MANAGEBOT_CONTACT = React.useContext(ManageBotContext)
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Dialog fullScreen open={MANAGEBOT_CONTACT.data.dialog}>
                <Box mb={2} p={2}>
                    <IconButton onClick={() => MANAGEBOT_CONTACT.setData({ ...MANAGEBOT_CONTACT.data, dialog: false })} >
                        <Close />
                    </IconButton>
                </Box>
                <Container maxWidth='lg'>
                    <TextBot />
                </Container>
            </Dialog>
        </div>
    )
}

export default DialogMain
import { Delete, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { ManagePlansContext } from '../../../context/ManagePlansContext'
import { TranslateContext } from '../../../context/TranslateContext'

const PlanList = () => {
    const MANAGE_PLANS_CONTEXT = React.useContext(ManagePlansContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_admin")
    const { data } = React.useContext(TranslateContext)

    async function getData() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/plan/get_all",
            post: false,
            admin: true,
            token_user: token
        })
        if (resp.data.success) {
            MANAGE_PLANS_CONTEXT.setData({ ...MANAGE_PLANS_CONTEXT.data, plan_data: resp.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    async function delPlan(e) {
        if (window.confirm(`Are your sure?`)) {
            const resp = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/plan/delete",
                post: true,
                admin: true,
                obj: {
                    id: e
                }
            })
            if (resp.data.success) {
                getData()
            }
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                {MANAGE_PLANS_CONTEXT.data?.plan_data ?
                    MANAGE_PLANS_CONTEXT.data?.plan_data.length > 0 ?
                        [...MANAGE_PLANS_CONTEXT.data?.plan_data].reverse().map((i, key) => {
                            return (
                                <Accordion
                                    key={key}
                                    sx={{ boxShadow: 5, borderRadius: 2 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}>
                                        <Typography>{i.name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid mb={1} container direction='column'>
                                            <Grid item>
                                                <Typography variant='caption' color='gray' >{data.msg_limit} {i.message_limit}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption' color='gray' >{data.cost} {i.cost}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption' color='gray' >{data.allow_bot} {i.allow_bot ? "Yes" : "No"}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption' color='gray' >{data.allow_grp_export} {i.allow_group_export ? "Yes" : "No"}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption' color='gray' >{data.home_plan_instance_limit} {i.instance_limit}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Stack direction={'column'}>
                                            <Typography variant='caption' fontWeight={'bold'} color='gray' >{data.descriptio}: {i.description}</Typography>
                                            <Stack alignItems={'flex-end'} direction={'column'}>
                                                <IconButton
                                                    onClick={() => delPlan(i.id)}
                                                    size='small' color='error'>
                                                    <Delete />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) : <Typography variant='caption' color={'gray'} >No plans found</Typography>
                    : null}
            </Stack>
        </div>
    )
}

export default PlanList
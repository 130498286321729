import React from 'react'
import { CardMedia, Container, Grid, Stack, Typography, Box, ImageList, Divider, Button, LinearProgress } from '@mui/material'
import { CheckCircleOutline, OpenInNew } from '@mui/icons-material'
import GetPlansPublic from '../../utils/GetPlansPublic'
import { TranslateContext } from '../../context/TranslateContext'
import { Link } from 'react-router-dom'

const PriceComp = ({ inner }) => {
    const { plans } = GetPlansPublic()
    const { data } = React.useContext(TranslateContext)
    const [mode, setMode] = React.useState("")

    React.useEffect(() => {
        const mo = localStorage.getItem('theme')
        if (mo === 'light' || mo === 'dark') {
            if (mo === 'light') {
                setMode(color.light)
            } else {
                setMode(color.dark)
            }
        } else {
            setMode(color.dark)
        }
    }, [])

    var color = {
        dark: {
            bg: '#161d25',
            heading: "#fefffe",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#fefffe',
            btntext: "#161d25",
            shadowcolor: "white",
            twoboxcolor: "#252d34"
        },
        light: {
            bg: "#fefeff",
            heading: "#212b36",
            orange: "#fa551d",
            textgray: "#8e9ca9",
            btncolor: '#212b36',
            btntext: "#fefeff",
            shadowcolor: "black",
            twoboxcolor: "#f8fafa"
        }
    }

    return (
        mode &&
        <Container sx={{ mt: inner ? 0 : 10 }} maxWidth='lg' >
            <Grid minHeight={inner ? "" : '100vh'} justifyItems='center' alignItems={'center'} container spacing={2} justifyContent='space-between'>
                <Grid xs={12} sm={12} lg={12} item>
                    <Stack alignItems={inner ? "" : 'center'} direction={'column'} spacing={3}>
                        <Typography align={inner ? "" : 'center'} variant='h4' fontWeight={'bold'}>{data.home_plan_heading}</Typography>
                        <Typography align={inner ? "" : 'center'} color={mode.textgray} variant='body'>{data.home_plan_heading_des}</Typography>
                        <Box mt={inner ? 0 : 2}>
                            <ImageList
                                sx={{
                                    gridAutoFlow: "column",
                                    gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                    gridAutoColumns: "minmax(160px, 1fr)",
                                    msOverflowStyle: 'none',
                                    overflowX: 'scroll',
                                    '::-webkit-scrollbar': {
                                        width: '10px'
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: mode.bg,
                                        borderRadius: 10
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: "#1B1B1B",
                                        borderRadius: 10
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: 'green',
                                    }
                                }}
                            >
                                <Stack direction={'row'} spacing={2}>
                                    {plans ? [...plans].reverse().map((i, key) => {
                                        return (
                                            <Box key={key} minWidth={250} p={5} bgcolor={mode.twoboxcolor} borderRadius={4}>
                                                <Stack direction={'row'} justifyContent='space-between'>
                                                    <Typography variant='h5' >{i.name}</Typography>
                                                    <Typography fontWeight={'bold'} fontFamily='monospace' >${i.cost}</Typography>
                                                </Stack>
                                                <Box mt={2} mb={4}>
                                                    <Divider variant='inset' />
                                                </Box>
                                                <Stack direction={'column'} spacing={2}>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                        <CheckCircleOutline sx={{ color: mode.orange }} />
                                                        <Typography variant='body2'>{i.instance_limit} {data.home_plan_instance_limit}</Typography>
                                                    </Stack>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                        <CheckCircleOutline sx={{ color: mode.orange }} />
                                                        <Typography variant='body2'>{i.message_limit} {data.home_plan_message_limit}</Typography>
                                                    </Stack>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                        <CheckCircleOutline sx={{ color: i.allow_bot ? mode.orange : "" }} />
                                                        <Typography variant='body2'>{data.home_plan_whatsapp_bot}</Typography>
                                                    </Stack>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                        <CheckCircleOutline sx={{ color: i.allow_group_export ? mode.orange : "" }} />
                                                        <Typography variant='body2'>{data.home_plan_whatsapp_export}</Typography>
                                                    </Stack>
                                                    <Box mt={2} mb={4}>
                                                        <Divider variant='inset' />
                                                    </Box>
                                                    <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                                        <Typography variant='body2'>{i.description}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Box mt={6}>
                                                    <Button
                                                        component={Link}
                                                        to={{ pathname: "/user/checkout", state: i }}
                                                        fullWidth variant='contained' style={{
                                                            textTransform: 'none', backgroundColor: mode.btncolor, borderRadius: 10, padding: 10, paddingRight: 20, paddingLeft: 20,
                                                            boxShadow: `0px 0px 45px -18px ${mode.shadowcolor}`, fontWeight: 'bold', color: mode.btntext
                                                        }}  >
                                                        {data.home_get_started}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    }) : <LinearProgress />}
                                </Stack>
                            </ImageList>
                        </Box>

                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PriceComp
import React from 'react'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'

const UserDash = () => {
    const lan = React.useContext(TranslateContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const [state, setState] = React.useState("")

    async function getData() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/gt_dash",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setState(res.data)
        }
    }
    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    return (
        <Box minHeight={'80vh'} p={2}>
            {state ?
                <Grid container spacing={2} >
                    <Grid item xs={12} lg={6} sm={6}>
                        <Box bgcolor={"#FFF1EE"} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight='bold' >{lan.data.user_dash_total_instance || "Total Instance:"} {state.instance || 0}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={6}>
                        <Box bgcolor={"#FFFFEE"} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight='bold' >{lan.data.user_dash_total_campaign} {state.campaign || 0}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={6}>
                        <Box bgcolor={"#F2FFEE"} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight='bold' >{lan.data.user_dash_btn_temp} {state.button || 0}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={6}>
                        <Box bgcolor={"#EEFFFC"} p={4} borderRadius={2}>
                            <Typography color={'black'} fontWeight='bold' >{lan.data.user_dash_list_temp} {state.list || 0}</Typography>
                        </Box>
                    </Grid>
                </Grid> : <LinearProgress />}
        </Box>
    )
}

export default UserDash
import React from 'react'
import { Box, Stack, Typography, Divider, LinearProgress, IconButton } from '@mui/material'
import { GlobalContext } from '../../../context/GlobalContext'
import moment from 'moment'
import { Download, Delete } from '@mui/icons-material'
import { TranslateContext } from '../../../context/TranslateContext'

const CampaignHistory = () => {
    const [state, setState] = React.useState({})
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")
    const [history, setHistory] = React.useState("")
    const { data } = React.useContext(TranslateContext)

    async function getData() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            post: false,
            path: "/api/campaign/get_histroy",
            token_user: token,
            admin: false
        })
        if (resp.data.success) {
            setHistory(resp.data.data)
        }
    }

    async function delCam(e) {
        if (window.confirm(data.are_you_sure)) {
            await GLOBAL_CONTEXT.hitAxios({
                post: true,
                path: "/api/campaign/del",
                admin: false,
                obj: { id: e }
            })
            getData()
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    const downloadFile = (e) => {

        // create file in browser
        const fileName = Date.now();
        const json = JSON.stringify(e, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return (
        <Box p={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between' spacing={2}>
                <Stack direction={'column'}>
                    <Typography fontWeight={'bold'} >{data.user_dash_menu_cam_history}</Typography>
                </Stack>
            </Stack>

            <Box mt={2} mb={2} >
                <Divider />
            </Box>

            <Stack direction={'column'} spacing={2}>
                {
                    history ?
                        [...history].reverse().map((i, key) => {
                            return (
                                <Box key={key} boxShadow={5} borderRadius={4} p={2} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} >
                                    <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                        <Stack direction={'column'}>
                                            <Typography color={'white'} fontWeight={'bold'}>Timestamp: {moment(i.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                                            <Typography color={i.status === 'completed' ? '#6CFF92' : "gray"} textTransform={'capitalize'} variant='caption'>{data.status} {i.status === 'completed' ? data.completed : i.status}</Typography>
                                            <Typography color='gray' textTransform={'capitalize'} variant='caption'>{data.typ} {i.type}</Typography>
                                            <Typography color='gray' textTransform={'capitalize'} variant='caption'>{data.sent_cout}: {JSON.parse(i.sent)?.length}</Typography>
                                            <Typography color='gray' textTransform={'capitalize'} variant='caption'>{data.templet_id}: {i.id}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1}>
                                            <IconButton onClick={() => {
                                                console.log("helna")
                                                var data = i
                                                delete data.id
                                                delete data.unique_id
                                                delete data.uid
                                                data.createdAt = moment(data.createdAt).format("DD/MM/YYYY - hh:mm A")
                                                data.sent = JSON.parse(data.sent)
                                                downloadFile(data)
                                            }} >
                                                <Download />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => delCam(i.id)}
                                                color='error' >
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )
                        }) : <LinearProgress />}
            </Stack >
        </Box>
    )
}

export default CampaignHistory
import { Box, Button, Chip, Dialog, Divider, IconButton, Stack, TextField } from '@mui/material'
import React from 'react'
import { BtnTempContext } from '../../../../context/BtnTempContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import { Add, Close } from '@mui/icons-material'
import { Container } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { TranslateContext } from '../../../../context/TranslateContext'

const DialogNoImg = () => {
    const BTNTEMP_CONTEXT = React.useContext(BtnTempContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_ADMIN + "_user")

    const [btn, setBtn] = React.useState([])
    const [load, setLoad] = React.useState(false)
    const [btnMsg, setBtnMsg] = React.useState({
        text: "",
        footer: '',
        buttons: btn,
        headerType: 1
    })
    const [state, setState] = React.useState({})

    const [nBtn, setNbtn] = React.useState({})

    async function getList() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/btntemp/get_btn_text",
            post: false,
            admin: false,
            token_user: token
        })
        if (res.data.success) {
            BTNTEMP_CONTEXT.setData({ ...BTNTEMP_CONTEXT.data, btnNoImgData: res.data.data, dialog_noimg: false })
        }
    }

    function delBtn(e) {
        var data = btn.filter(i => i.buttonId !== e)
        setBtn(data)
    }

    async function addNew() {
        setLoad(true)
        await GLOBAL_CONTEXT.hitAxios({
            obj: {
                text: btnMsg.text,
                footer: btnMsg.footer,
                buttons: btn,
                headerType: 1,
                name: btnMsg.grp_title
            },
            path: "/api/btntemp/add",
            admin: false,
            post: true
        })
        getList()
        setLoad(false)
        setBtn([])
    }



    return (
        <Dialog open={BTNTEMP_CONTEXT.data.dialog_noimg} fullScreen>
            <Box mb={2} p={2}>
                <IconButton
                    onClick={() => BTNTEMP_CONTEXT.setData({ ...BTNTEMP_CONTEXT.data, dialog_noimg: false })}
                >
                    <Close />
                </IconButton>

            </Box>
            <Container maxWidth='lg'>
                <Stack direction={'column'} spacing={2} >

                    <TextField
                        onChange={(e) => setBtnMsg({ ...btnMsg, grp_title: e.target.value })}
                        label={data.shrt_grp_ttl} fullWidth size='small' />

                    <TextField
                        onChange={(e) => setBtnMsg({ ...btnMsg, text: e.target.value })}
                        label={data.entr_grp_btn} fullWidth size='small' />

                    <TextField
                        onChange={(e) => setBtnMsg({ ...btnMsg, footer: e.target.value })}
                        label={data.entr_btn_footer} fullWidth size='small' />

                    <Box>
                        {btn.map((i, key) => {
                            return (
                                <Chip
                                    onDelete={() => delBtn(i.buttonId)}
                                    sx={{ mr: 2, mb: 2 }} color='warning' label={i.buttonText?.displayText} />

                            )
                        })}
                    </Box>
                    <Stack direction={'row'}>
                        <Button
                            startIcon={<Add />}
                            onClick={() => setState({ ...state, dialog: true })}
                            sx={{ textTransform: 'none' }} size='small' variant='outlined' >{data.add_btn}</Button>
                    </Stack>
                    <Divider />
                    <LoadingButton
                        loading={load}
                        onClick={addNew}
                        disabled={btnMsg.text && btnMsg.footer && btn.length > 0 && btnMsg.grp_title ? false : true}
                        fullWidth
                        variant='contained'
                        size='small'
                    >{data.add_ths_btn_temp}</LoadingButton>
                </Stack>
            </Container>

            <Dialog fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <TextField
                            onChange={(e) => setNbtn({ ...nBtn, buttonText: e.target.value })}
                            label="Button Name" fullWidth size='small' />
                        <Button
                            onClick={() => {
                                setBtn([...btn, { buttonId: Date.now(), buttonText: { displayText: nBtn.buttonText }, type: 1 }])
                                setNbtn({})
                                setState({ ...state, dialog: false })
                            }}
                            disabled={nBtn.buttonText ? false : true}
                            size='small' fullWidth variant='contained' >{data.add_new_btn}</Button>
                    </Stack>
                </Box>
                <Button
                    color='warning' variant='contained' onClick={() => setState({ ...state, dialog: false })} >{data.close}</Button>
            </Dialog>
        </Dialog>
    )
}

export default DialogNoImg